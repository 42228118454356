import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import ReactDatatable from "@ashvin27/react-datatable";
import imgBackground from "../../../img/reciept.jpeg";
import moment from "moment";
import html2canvas from 'html2canvas';
import { useHttp } from "../../../hooks/useHttps";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
  DatePicker,
} from "antd";
import axios from "axios";
import config from "../../../config";
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const sevenDaysAgo = new Date(currDay - 7 * days);
const plus1day = new Date(currDay + 1 * days);
export default function Employers() {
  const [transactionData, setTransactionData] = useState([]);
  const [load, setLoad] = useState(false);
  const contentRef = useRef(); // Create a reference to the div you want to convert to PDF
  const [showReceipt, setShowReceipt] = useState(false); // Toggle receipt visibility
  const [noTransactionDatas, setNoTransactions] = useState(false);
  const [receieptInfo, setRecieiptInfo] = useState({})
  const [loadTransactionDatas, setLoadTransactions] = useState(false);
  const [billMethod, setBillMethod] = useState("");
  const [transactionType, setTranstype] = useState("");
  const [date, setDate] = useState(moment(sevenDaysAgo).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const columns = [
    {
      key: "txRef",
      text: "Tranaction Ref",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "clientRef",
      text: "Client Ref",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "trans_type",
      text: "Transaction Type",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "amount",
      text: "Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "charged_amount",
      text: "Charged Amount",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "fee",
      text: "Fee",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => { },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => { },
      onDoubleClick: (event) => { },
    },


  ];

  const downloadPDF = async () => {
    // Temporarily show the receipt
    setShowReceipt(true);

    // Wait for the DOM to render the receipt
    await new Promise((resolve) => setTimeout(resolve, 0));

    const element = contentRef.current; // Get the receipt content
    html2canvas(element, {
      scale: 3, // Higher scale for better quality
      useCORS: true,
      backgroundColor: null,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Download as an image
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "transaction-receipt.png";
      link.click();

      // Alternatively, download as a PDF
      // const pdf = new jsPDF("p", "mm", "a4");
      // const imgWidth = 210;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.save("receipt.pdf");

      // Hide the receipt again after download
      setShowReceipt(false);
    });
  };


  const getTransactions = () => {
    setLoadTransactions(true);
    axios
      .get(
        `${config.baseUrl
        }/v1/transactions/users/paged?userId=${sessionStorage.getItem(
          "userId"
        )}&size=50&page=0&endDate=${endDate}&startDate=${date}&transactionType=${transactionType}&billingMethod=${billMethod}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (transactionsData.length > 0) {
            setTransactionData(transactionsData);
            setLoadTransactions(false);
            setNoTransactions(false);
          } else {
            setLoadTransactions(false);
            setNoTransactions(true);
            setTransactionData([]);
          }
        } else {
          setLoadTransactions(false);
          setNoTransactions(true);
          setTransactionData([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    if ((date && endDate) || billMethod || transactionType) getTransactions();
  }, [date, endDate, billMethod, transactionType]);

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1
      }&size=${pageSize}&endDate=${endDate}&startDate=${date}&transactionType=${transactionType}&billingMethod=${billMethod}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl
        }/v1/transactions/employee/paged?userId=${sessionStorage.getItem(
          "userId"
        )}&${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (transactionsData.length > 0) {
            setTransactionData(transactionsData);
            setLoadTransactions(false);
            setNoTransactions(false);
          } else {
            setLoadTransactions(false);
            setNoTransactions(true);
          }
        } else {
          setLoadTransactions(false);
          setNoTransactions(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChange = (date, dateString) => {
    setDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {" "}
                  <h5 class="card-title">Transaction History </h5>
                </div>
                <div className="px-3 d-flex justify-content-end align-items-center pt-3">
                  <div className="row">
                    <div className="col-md-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={billMethod}
                        style={{ paddingRight: "2rem" }}
                        onChange={(e) => setBillMethod(e.target.value)}
                      >
                        <option selected>Select Channel</option>
                        <option value="WALLET">WALLET</option>
                        <option value="BANK_TRANSFER">BANK TRANSFER</option>
                        <option value="CARD_PAYMENT">CARD PAYMENT</option>
                        <option value="DIRECT_DEBIT">DIRECT DEBIT</option>
                        <option value="PAYDAY_LOAN">PAYDAY LOAN</option>
                      </select>
                    </div>{" "}
                    <div className="col-md-4">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={transactionType}
                        style={{ marginRight: "2rem" }}
                        onChange={(e) => setTranstype(e.target.value)}
                      >
                        <option selected>Select Transaction Type</option>
                        <option>CREDIT</option>
                        <option>DEBIT</option>
                      </select>
                    </div>{" "}
                    <div className="col-md-4">
                      <RangePicker
                        style={{
                          height: "3rem",
                          borderRadius: "10px",
                          width: "100%",
                        }}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"> Name</th>
                      <th scope="col">Account Number</th>
                      <th scope="col"> Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Fee</th>
                      <th scope="col">Status</th>

                      <th scope="col">Date</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  {!loadTransactionDatas && (
                    <tbody>
                      {transactionData.length > 0 && (
                        <>
                          {transactionData.map((data) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    whiteSpace:
                                      data?.destAcctName?.length > 30
                                        ? "wrap"
                                        : "no-wrap",
                                  }}
                                >
                                  {data.type === "CREDIT"
                                    ? data?.srcAcctName?.replace("INT'LMISHTRANZA /", "")
                                    : data.destAcctName?.replace("INT'LMISHTRANZA /", "")}
                                </td>
                                <td>
                                  {data.type === "CREDIT"
                                    ? data?.srcAcctNumber
                                    : data.destAcctNumber}
                                </td>
                                <td>{data.type}</td>
                                <td>
                                  {data.destCurrency}{" "}
                                  {data.amount
                                    ? Intl.NumberFormat("en-US").format(
                                      data.amount
                                    )
                                    : ""}
                                </td>

                                <td>
                                  {data.destCurrency}{" "}
                                  {data.processorFee
                                    ? Intl.NumberFormat("en-US").format(
                                      data.processorFee
                                    )
                                    : 0}
                                </td>
                                <td>
                                  <span
                                    className={
                                      {
                                        SUCCESS: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        NEW: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESS: "badge bg-warning",
                                        TIMEOUT: "badge bg-danger",
                                      }[data.status]
                                    }
                                  >
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  {data.createdAt
                                    ? moment(data.createdAt).format(
                                      "DD-MMM-YYYY h:mm A"
                                    )
                                    : ""}
                                </td>

                                <td>
                                  <i className="fa fa-download" style={{ color: '#1FC157', cursor: 'pointer' }} onClick={() => {
                                    console.log(data)
                                    setRecieiptInfo(data)
                                    downloadPDF()
                                  }}>

                                  </i>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  )}
                </table>
                {transactionData.length === 0 && !loadTransactionDatas && (
                  <p className="text-center pt-5 pb-5">No Transactions</p>
                )}
                {loadTransactionDatas && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
              {transactionData?.length > 0 &&
                <div className="d-flex  justify-content-center align-items-center pt-5 pb-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />{" "}
                </div>}
            </div>
          </div>
        </div>
      </div>
      {showReceipt &&
        <div ref={contentRef} style={{
          position: "relative",
          top: "-9999px", // Completely off-screen

        }}>
          <div className="mt-3 position-relative" id="receipt">
            <div className="pos-class-main">
              <div style={{ fontFamily: "'Plus Jakarta Sans', sans-serif'" }}>
                <div className="w-100" >
                  <div style={{ textAlign: "center", color: receieptInfo?.type === 'CREDIT' ? '#1FC157' : '#D10000', fontSize: '26px', fontWeight: '800' }}>&#8358;{receieptInfo?.amount?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}</div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      fontWeight: "bold",
                      color: '#1E2028'
                    }}
                  >
                    FUND TRANSFER
                  </div>
                  <div style={{ textAlign: "center" }}> {receieptInfo?.createdAt
                    ? moment(receieptInfo.createdAt).format(
                      "DD-MMM-YYYY h:mm A"
                    )
                    : ""}</div>
                </div>
                <div className="pt-4">
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Reciever Name</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.destAcctName}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Reciever Account</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.destAcctNumber}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Sender Name</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.srcAcctName}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Transaction Reference </div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.merchantReference}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Narration </div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.narration}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Fee </div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      &#8358;{receieptInfo?.processorFee}
                    </div>
                  </div>
                  {receieptInfo?.purpose === "BILL_PAYMENT" &&
                    <div>
                      {receieptInfo?.billPayment.category === "ELECTRICITY" &&
                        <div>
                          <div
                            style={{
                              border: "1px solid #F5F6FA",
                              background: "#F5F6FA",
                              borderRadius: "7px",
                            }}
                            className="mb-2 p-2"
                          >
                            <div style={{ fontSize: '12px', color: '#444444' }}>Token </div>
                            <div
                              className="font-weight-bold b"
                              style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                            >
                              {receieptInfo?.billPayment?.token}
                            </div>
                          </div>
                          <div
                            style={{
                              border: "1px solid #F5F6FA",
                              background: "#F5F6FA",
                              borderRadius: "7px",
                            }}
                            className="mb-2 p-2"
                          >
                            <div style={{ fontSize: '12px', color: '#444444' }}>Address </div>
                            <div
                              className="font-weight-bold b"
                              style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                            >
                              {receieptInfo?.billPayment?.address}
                            </div>
                          </div>
                        </div>}
                    </div>}

                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Status</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#1FC157", fontSize: '14px' }}
                    >
                      SUCCESS
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <img src={imgBackground} className="" />
            </div>
          </div>
        </div>}
    </Layout>
  );
}
