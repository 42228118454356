import React, { useState, useEffect } from "react";
import Tabs from "../../employeeTabs";
import Layout from "../../../containers/Layout";
import config from "../../../config";
import { Modal, notification, Drawer, Pagination, Button, Spin } from "antd";
import { useHttp } from "../../../hooks/useHttps";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
export default function EmployeeInfo(props) {
  const [visible, setVisible] = useState(false);
  const [rowMsg, setRowMsg] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [rowId, setRowID] = useState("");
  const showDraw = (id) => {
    setVisible(true);
    setRowID(id.id);
    setRowMsg(id?.message);
  };

  const [noPayrollDetailsData, setNoPayrollDetailsDatas] = useState(false);
  const [loadPayrollDetailsData, setLoadPayrollDetailsDatas] = useState(true);
  const [payrollDatas, setPayrollDetailsDatas] = useState([]);
  const [listCheck, setListCheck] = useState([]);

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const payrollId = props.history.location.state.rowId
    ? props.history.location.state.rowId
    : "";

  useEffect(() => {
    getpayrollDetail()
  }, []);


  const getpayrollDetail = () => {
    axios
    .get(
      `${
        config.baseUrl
      }/v1/payroll-details/paged?employerId=${sessionStorage.getItem(
        "employer_id"
      )}&payrollId=${payrollId}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      if (res.data.status) {
        if (res.data.result.length > 0) {
          setTotalItems(res.data.totalPages * 10);
          setNoPayrollDetailsDatas(false);
          setLoadPayrollDetailsDatas(false);
          const updatedData = res.data.result.map((item) => ({
            ...item, // Spread existing properties
            checked: false, // Add new property
          }));
          setPayrollDetailsDatas(updatedData);
        } else {
          setNoPayrollDetailsDatas(true);
          setLoadPayrollDetailsDatas(false);
          setPayrollDetailsDatas([]);
        }
      } else {
        setNoPayrollDetailsDatas(true);
        setLoadPayrollDetailsDatas(false);
        setPayrollDetailsDatas([]);
      }
    })
    .catch((err) => {
      setNoPayrollDetailsDatas(true);
      if (err) {
      }
    });
  }

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closeDraw = () => {
    setVisible(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const syncPaymetn = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to sync this payment method?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/payroll-details/sync-payment-method/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              getpayrollDetail()
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },

      onCancel() {},
    });
  };

  const retryPayroll = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to re-try this payroll?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/payroll-details/retry/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              getpayrollDetail()
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },

      onCancel() {},
    });
  };

  const displayMsg = () => {
    Notification("info", "Message Detail", rowMsg);
  };

  const deletePayroll = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this payroll?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(`${config.baseUrl}/v1/payroll-details/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              getpayrollDetail()
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },
      onCancel() {},
    });
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    const updatedData = payrollDatas.map((item) => ({ ...item, checked }));
    setPayrollDetailsDatas(updatedData);
    setListCheck(updatedData);
  };

  const handleCheckboxChange = (id) => {
    const updatedData = payrollDatas.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setPayrollDetailsDatas(updatedData);
    setListCheck(updatedData?.filter((item) => item?.checked));
    const allChecked = updatedData.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&payrollId=${payrollId}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/payroll-details/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setTotalItems(res.data.totalPages * 10);
            setNoPayrollDetailsDatas(false);
            setLoadPayrollDetailsDatas(false);
            const updatedData = res.data.result.map((item) => ({
              ...item, // Spread existing properties
              checked: false, // Add new property
            }));
            setPayrollDetailsDatas(updatedData);
          } else {
            setNoPayrollDetailsDatas(true);
            setLoadPayrollDetailsDatas(false);
            setPayrollDetailsDatas([]);
          }
        } else {
          setNoPayrollDetailsDatas(true);
          setLoadPayrollDetailsDatas(false);
          setPayrollDetailsDatas([]);
        }
      })
      .catch((err) => {
        setNoPayrollDetailsDatas(true);
        if (err) {
        }
      });
  };

  const retryPayrollBulk = (id) => {
    axios
      .put(
        `${config.baseUrl}/v1/payroll-details/retry`,
        {
          detailIds: listCheck?.map((item) => {
            return item.id;
          }),
          employerId: sessionStorage?.getItem("employer_id"),
          payrollId: payrollId,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getpayrollDetail();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirmPayrollRetry = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to retry the selected payroll payout(s)?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        retryPayrollBulk();
      },
      onCancel() {},
    });
  };

  const formatNumber = (num) => {
    return Intl.NumberFormat().format(num);
  };

  return (
    <Layout>
      <div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body">
                <h5 class="card-title">Payroll Details </h5>
                <div className="d-flex justify-content-end  container">
                  {listCheck?.length > 0 && (
                    <Button
                      className=""
                      onClick={showDeleteConfirmPayrollRetry}
                      style={{
                        background: "#1FC157",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      Retry
                    </Button>
                  )}
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">
                        
                        </th>
                        <th scope="col">Employee Code</th>
                        <th scope="col">Net Salary</th>
                        <th scope="col">Fee</th>
                        <th scope="col">Beneficiary Account</th>
                        <th scope="col">Beneficiary Name</th>
                        <th scope="col">Institution</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollDatas.map((data) => {
                        return (
                          <tr>
                            <td>
                              <div class="form-check">
                                <input
                                  class="form-check-input border-checks"
                                  type="checkbox"
                                  checked={data.checked}
                                  onChange={() => handleCheckboxChange(data.id)}
                                />
                              </div>
                            </td>
                            <td>
                              {data.employeeCode ? data?.employeeCode : "NA"}
                            </td>
                            <td>
                              {data.salaryCurrency}{" "}
                              {data.monthlyGrossSalary
                                ? formatNumber(data.monthlyGrossSalary)
                                : 0}
                            </td>
                            <td>
                              {data.salaryCurrency} {data.fee}
                            </td>

                            <td>{data.paymentMethod.accountIdentifier}</td>
                            <td>{data.paymentMethod.accountName}</td>
                            <td>{data.paymentMethod.institutionName}</td>
                            <td>
                              {data.createdAt
                                ? data.createdAt.slice(0, 10)
                                : ""}
                            </td>
                            <td>
                              <span
                                className={
                                  {
                                    SUCCESS: "badge bg-success",
                                    NEW: "badge bg-info",
                                    FAILED: "badge bg-danger",
                                    PENDING: "badge bg-warning",
                                  }[data.status]
                                }
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>
                              <Button
                                onClick={showDraw.bind(this, data)}
                                type="primary"
                                danger
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                Actions
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {loadPayrollDetailsData && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
                {noPayrollDetailsData && (
                  <p className="text-center pt-5 pb-5">
                    No Payroll Details Yet.
                  </p>
                )}

                <div className="text-center mt-5 mb-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Drawer
          title="Actions"
          placement="right"
          visible={visible}
          onClose={closeDraw}
        >
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={displayMsg}
          >
            Payroll Detail Message
          </div>
          <hr />
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={syncPaymetn}
          >
            Sync Payment method
          </div>
          <hr />
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={retryPayroll}
          >
            Retry
          </div>
          <hr />
          <div
            style={{ color: "red", cursor: "pointer" }}
            onClick={deletePayroll}
          >
            {" "}
            Delete
          </div>
          <hr />
        </Drawer>
      </div>
    </Layout>
  );
}
