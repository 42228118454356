import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel, Select } from "antd";
import EmployStats from "./employee-dashboord-stats";
import { useHttp } from "../../hooks/useHttps";
import axios from "axios";
import moment from "moment";
import config from "../../config";
import banner2 from "../../assets/Banners/banner2.png"
import banner3 from "../../assets/Banners/banner3.png"
import banner4 from "../../assets/Banners/banner4.png"
import banner5 from "../../assets/Banners/banner5.png"

export default function Dashboard() {
  const [employeeStats, setEmployeeStats] = useState([]);
  const [loadTrans, transactionHistoryData] = useHttp(
    `${
      config.baseUrl
    }/v1/transactions/users/paged?page=0&size=20&userId=${sessionStorage.getItem(
      "userId"
    )}`,
    []
  );

  return (
    <div>
      <div class="">
        <div className="container">
          <div className=" mb-5" style={{ width: "100% !important" }}>
            <div className="col-md-12">
              <Carousel autoplay style={{ width: "100%" }}>
                <div>
                  <img src={banner2} className="w-100"/>
                </div>
                <div>
                  <img src={banner3} className="w-100" />
                </div>
                <div>
                  <img src={banner4} className="w-100" />
                </div>
                <div>
                  <img src={banner5} className="w-100" />
                </div>
                {/* <div>
                  <img src="https://res.cloudinary.com/didqqm6jl/image/upload/v1631637277/Banners/SALARIOPAY-WEBPAGE-BANNERS5.png" />
                </div>{" "}
                <div>
                  <img src="https://res.cloudinary.com/didqqm6jl/image/upload/v1631637277/Banners/SALARIOPAY-WEBPAGE-BANNERS6.png" />
                </div>{" "}
                <div>
                  <img src="https://res.cloudinary.com/didqqm6jl/image/upload/v1631637277/Banners/SALARIOPAY-WEBPAGE-BANNERS7.png" />
                </div>{" "}
                <div>
                  <img src="https://res.cloudinary.com/didqqm6jl/image/upload/v1631637277/Banners/SALARIOPAY-WEBPAGE-BANNERS4.png" />
                </div> */}
              </Carousel>
            </div>
          </div>
        </div>
        <EmployStats />

        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div class="card ">
              <div class="card-header font-weight-bold">QUICK MENU</div>
              <div class="card-body mb-0 pb-0">
                <div className="container">
                  <div class="row">
                    <div class="col-sm-12 col-md-3">
                      <Link to="/my-work-hub">
                        <div class="card folder">
                          <div
                            class="card-body"
                            style={{
                              background: "#f5f5f5",
                              borderRadius: "20px",
                            }}
                          >
                            <div class="folder-icon">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/airtime.png"
                                alt=""
                                width="40"
                              />
                            </div>
                            <div class="folder-info">
                              <a href="" className="h5 pt-3">
                                Payslip
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div class="col-sm-12 col-md-3">
                      <Link to="/employee-benefits">
                        <div class="card folder">
                          <div
                            class="card-body"
                            style={{
                              background: "#f5f5f5",
                              borderRadius: "20px",
                            }}
                          >
                            <div class="folder-icon">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/databundle.png"
                                alt=""
                                width="40"
                              />
                            </div>

                            <div class="folder-info">
                              <a href="#" className="h5 pt-3">
                                Benefits
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div class="col-sm-12 col-md-3">
                      <Link to="/payments">
                        <div class="card folder">
                          <div
                            class="card-body"
                            style={{
                              background: "#f5f5f5",
                              borderRadius: "20px",
                            }}
                          >
                            <div class="folder-icon">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png"
                                alt=""
                                width="40"
                              />
                            </div>

                            <div class="folder-info">
                              <a href="#" className="h5 pt-3">
                                Payment
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div class="col-sm-12 col-md-3">
                      <Link to="/accounts">
                        <div class="card folder">
                          <div
                            class="card-body"
                            style={{
                              background: "#f5f5f5",
                              borderRadius: "20px",
                            }}
                          >
                            <div class="folder-icon">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png"
                                alt=""
                                width="40"
                              />
                            </div>

                            <div class="folder-info">
                              <a href="#" className="h5 pt-3">
                                Accounts
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>

                    {/* <div class="col-sm-12 col-md-3">
                      <Link
                        to="market_place
"
                      >
                        <div class="card folder">
                          <div
                            class="card-body"
                            style={{
                              background: "#f5f5f5",
                              borderRadius: "20px",
                            }}
                          >
                            <div class="folder-icon">
                              <img
                                src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/marketplace.png"
                                alt=""
                                width="40"
                              />
                            </div>

                            <div class="folder-info">
                              <a href="#" className="h5 pt-3">
                                Market Place
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body pb-5">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="card-title">Transaction History </h5>
                  </div>
                </div>
                {transactionHistoryData.length > 0 ? (
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col"> Name</th>

                          <th scope="col">Account Number</th>
                          <th scope="col"> Type</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Fee</th>
                          <th scope="col">Status</th>
                          <th scope="col">Date</th>
                          <th scope="col">Channel</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {transactionHistoryData.map((data) => {
                            return (
                              <tr>
                                <td
                                  style={{
                                    whiteSpace:
                                      data?.destAcctName?.length > 30
                                        ? "wrap"
                                        : "no-wrap",
                                  }}
                                >
                                  {data.type === "CREDIT"
                                    ? data?.srcAcctName
                                    : data.destAcctName}
                                </td>
                                <td>
                                  {data.type === "CREDIT"
                                    ? data?.srcAcctNumber
                                    : data.destAcctNumber}
                                </td>
                                <td>{data.type}</td>
                                <td>
                                  {data.destCurrency}{" "}
                                  {data.amount
                                    ? Intl.NumberFormat("en-US").format(
                                        data.amount
                                      )
                                    : ""}
                                </td>

                                <td>
                                  {data.destCurrency}{" "}
                                  {data.processorFee
                                    ? Intl.NumberFormat("en-US").format(
                                        data.processorFee
                                      )
                                    : 0}
                                </td>
                                <td>
                                  <span
                                    className={
                                      {
                                        SUCCESS: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        NEW: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESS: "badge bg-warning",
                                        TIMEOUT: "badge bg-danger",
                                      }[data.status]
                                    }
                                  >
                                    {data.status}
                                  </span>
                                </td>
                                <td>
                                  {data.createdAt
                                    ? moment(data.createdAt).format(
                                        "DD-MMM-YYYY h:mm A"
                                      )
                                    : ""}
                                </td>

                                <td>
                                  {data.channel
                                    ? data.channel?.replace("_", " ")
                                    : ""}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="d-flex justify-content-center align-items-center pt-5 pb-5">
                    No Transaction History
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
