import React, { Fragment, useState, useEffect } from "react";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import { ThreeDots } from "react-loader-spinner";
import { Link, useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import Location from "../../../containers/Location/Location1";
import Location2 from "../../../containers/Location/Location2";
import { NumberFormatter } from "../../../formatter/index";
import CrudOptimizer from "../../../containers/UtilityCrudEmployer/Index";
import {
  Upload,
  DatePicker,
  Dropdown,
  Tabs,
  Spin,
  Tooltip,
  Checkbox,
  Drawer,
  Button,
  Space,
  Alert,
  Menu,
  Pagination,
  message,
  Modal,
  Select,
  notification,
} from "antd";
import "react-dropzone-uploader/dist/styles.css";
import { Bars } from "react-loader-spinner";
import Dropzone from "react-dropzone";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import { useForm, Controller } from "react-hook-form";
import "./datatable.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHttp } from "../../../hooks/useHttps";
import moment from "moment";
import dayjs from "dayjs";
const dateFormat = "YYYY/MM/DD";
const userRole = sessionStorage.getItem("activeRole");
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const currDate = moment().format("YYYY-MM-DD");
const sevenDaysAgo = new Date(currDay - 7 * days);
const plus1day = new Date(currDay + 1 * days);
export default function EmployeeOnboarding() {
  const [excelFile, setExcelFile] = useState({});
  const [accOption, setAcountOption] = useState("");
  const [listChecks, setListCheck] = useState([]);
  const [successUpload, setSuccessUpload] = useState([]);
  const [dataRow, setDataRow] = useState({});
  const [visible, setVisible] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [addTypeName, setAddTypeName] = useState("");
  const [filteringPagination, setFilteringPagination] = useState(false);
  const [bankLists, setBankLists] = useState([]);
  const [totalItemsPaymentMethods, setTotalItemsPaymentMethods] = useState("");
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [paymentMethodLists, setPaymentMethodLists] = useState([]);
  const [statusFilter, setStatusFilter] = useState("active");
  const [openBulkSalaryUpdate, setOpenBulkSalaryUpdate] = useState(false);
  const [currentSalaryUpdateId, setCurrentSalaryUpdateId] = useState("");
  const [positionOptions, setPositionOptions] = useState([]);
  const [accountNumPaymentMethod, setAccountNumPaymentMethod] = useState("");
  const [levelOptions, setLevelOptions] = useState([]);
  const [departmentLists, setDepartmentOptions] = useState([]);
  const [monthlyNetSalaryVal, setMonthltyNetSalaryValue] = useState("");
  const [loadFilter, setLoadFilter] = useState(false);
  const [payScale, setPayScale] = useState("");
  const [downloadTemp, setDownloadTemp] = useState(false);
  const [loadEmployees, setLoadEmployees] = useState(false);
  const [loadCrudDatas, setCrudDatas] = useState(false);
  const [prorateType, setProrateType] = useState("");
  const [uploadBulkLoad, setLoadUpdateBulk] = useState(false);
  const [prorateValue, setProrateValue] = useState("");
  const [showUploadRes, setShowUploadres] = useState(false);
  const [pin, setPin] = useState("");
  const [dobReg, setDobReg] = useState("");
  const [enableAllPayment, setEnableAllPayment] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [paymentCountry, setPaymentCountry] = useState("");
  const [mobileMoneyErr, setMobileMoneyError] = useState(false);
  const [loadEmployeesPayment, setLoadEmployeesPayment] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");
  const [validUpload, setValidUpload] = useState([]);
  const [employerPin, setEmployerPin] = useState("");
  const [loadVerify, setLoadVerify] = useState(false);
  const [pagePaymentMethods, setPagePaymentMethod] = useState(1);
  const [kycModal, setKycModal] = useState(false);
  const [pfaOptionsLists, setPfaOptions] = useState([]);
  const [failedUpload, setFailedUpload] = useState([]);
  const [failedErrMsgs, setFailedErrMessages] = useState([]);
  const [failedMsgModal, setFailedMsgModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payGroupDataList, setPaygroupDataList] = useState([]);
  const [loadExcel, setLoadExcel] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [loadBank, setLoadBank] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [totalData, settotalData] = useState(0);
  const [locationinfo, setLocationInfo] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [pinBulk, setPinBulk] = useState(false);
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const history = useHistory();

  //kycModal
  const [personalIdentity, setPersonalIdentity] = useState("");
  const [businessIdentity, setBusinessIdentity] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [kyccountry, setKycCountry] = useState("");
  const [kycId, setKycId] = useState("");
  const [kycaddress, setKycAddress] = useState("");
  const [countryBusiness, setCountryBusiness] = useState("");
  const [kycfirstName, setKycFirstName] = useState("");
  const [kyclastName, setKycLastName] = useState("");
  const [kyctitle, setKycTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [kycposition, setKycPosition] = useState("");
  const [kycotherName, setKycOthertName] = useState("");
  const [kycgender, setKycGender] = useState("");
  const [kycmaritalStatus, setKycMaritalStatus] = useState("");
  const [kycphoneNumber, setKycPhoneNumber] = useState("");
  const [kycpvcState, setKycPVCState] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [pinConfirm, setPinConfirm] = useState("");
  const [annualGrosserySalaryUpdate, setAnnualGrosseryUpdate] = useState("");
  const [kycdob, setKycDob] = useState("");
  const [kycdobReg, setKycDobReg] = useState("");
  const [identityDetails, setIdentityDetails] = useState("");
  const [businessIdentityDetails, setBusinessIdentityDetails] = useState({});
  const [identityType, setIdentityType] = useState([]);
  const [businessIdentityType, setBusinessIdentityType] = useState([]);
  const [confirmationResult, setConfirmationResult] = useState({});
  const [salaryEmployeeDataUpdate, setDataEmployeeSalaryUpdate] = useState([]);
  const [contactInformation, setContactInfo] = useState({});
  const [lookUpKycModal, setOpenLookUpKycModal] = useState(false);
  const [selectedIdentityType, setSelectedIdentity] = useState("");
  const [selectedBusinessdentityType, setSelectedBusinessIdentityType] =
    useState("");
  const [fiscalMonth, setFiscalMonth] = useState("");
  const [pvcState, setPVCState] = useState("");

  const [openBulkPaymentMethod, setOpenBulkSPaymentUpdate] = useState(false);
  const [enablePaymentMethodAll, setEnableAllPaymentMethod] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [dateOfRegistration, setDateOfRegistration] = useState("");
  const [industry, setIndustry] = useState("");
  const [employeeLevelSelected, setEmployeeLevelSelected] = useState([]);
  const [statusFilterBar, setStatusFilterBar] = useState("Active");
  const [positionSelected, setPositionsSelected] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState([]);
  const [employeeTypeSelected, setEmployeeTypeSelected] = useState([]);
  const [size, setSize] = useState("");
  const [website, setWebsite] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [countryOnboard, setCountryOnboard] = useState("");
  const [registerType, setRegisterType] = useState("");
  const [nhfNumber, setNhfNumber] = useState("");
  const [ownership, setOwnership] = useState("");
  const [penComCode, setPenComCode] = useState("");
  const [kyctaxId, setKycTaxId] = useState("");
  const [showNetCompute, setShowNetCompute] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [personalEmail, setPersonalEmail] = useState("");
  const [steps, setSteps] = useState("personal");
  //paginations
  const [page, setPage] = useState(1);
  const [pageSalaryUpdate, setPageSalaryUpdate] = useState(1);
  const [totalItemsSalaryUpdate, setTotalItemSalaryUpdate] = useState("");
  const [totalItems, setTotalItems] = useState("");
  const [loadingProrate, prorateDataType] = useHttp(
    `${config.baseUrl}/v1/options/PRORATE_TYPES`,
    []
  );
  const [api, contextHolder] = notification.useNotification();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const employeeBvn = watch("employeeBvn");
  let typeBVN;
  //upload-file-area is
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [message, setMessage] = useState("");
  const [checkWorkOptions, setCheckWorkOptions] = useState(["Pension", "Tax"]);

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const onChangeCheckbox = (checkedValues) => {
    setCheckWorkOptions(checkedValues);
  };

  const checkOptions = [
    {
      label: "Pension",
      value: "Pension",
    },

    {
      label: "Tax",
      value: "Tax",
    },
  ];
  // called every time a file's `status` changes
  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files[0]);
    }
  };

  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const getBvnType = () => {
    typeBVN = identityType?.find((d) => d?.type === "BVN");
    return typeBVN.type;
  };

  const addEmployeeModal = () => {
    setOpenEmployee(true);
    setAddEmployee(true);
    setImportEmployee(false);
    setOnboardChecklist(false);
  };

  const importEmployeeModal = () => {
    setAddEmployee(false);
    setOpenEmployee(true);
    setImportEmployee(true);
    setOnboardChecklist(false);
  };

  const bulkInvite = () => {
    Modal.confirm({
      title: "Are you sure you want to resend Invite to these employee(s)?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      onOk() {
        axios
          .post(
            `${
              config.baseUrl
            }/v1/employees/resend/bulk/${sessionStorage.getItem(
              "employer_id"
            )}`,
            {
              employeeIds: listChecks?.map((item) => {
                return item.id;
              }),
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              Notification("success", "Success", res.data.message);
              setTimeout(() => {
                window.location.reload(false);
              }, 1500);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const htmlErrorRenders = (errorsVal) => {
    return (
      <>
        <ul>
          {errorsVal?.map((d) => {
            return <li>{d}</li>;
          })}
        </ul>
      </>
    );
  };

  const openNotificationWithIcon = (type, msg, errosVal) => {
    api[type]({
      message: msg,
      description: htmlErrorRenders(errosVal),
    });
  };

  const requestPaymentMethodBulk = () => {
    Modal.confirm({
      title:
        "Are you sure you want to request payment method for these employee(s)?",
      icon: <ExclamationCircleOutlined />,
      content: " ",
      okText: "Yes",
      onOk() {
        axios
          .post(
            `${config.baseUrl}/v1/payment-methods/request/bulk`,
            {
              employeeIds: listChecks?.map((item) => {
                return item.id;
              }),
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              Notification("success", "Success", res.data.message);
              setTimeout(() => {
                window.location.reload(false);
              }, 1500);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const items = [
    {
      key: "21",
      label: (
        <div
          onClick={() => {
            history.push("/self-onboarding");
          }}
        >
          Self Onboarding via Link
        </div>
      ),
    },
    {
      key: "1",
      label: <div onClick={addEmployeeModal}>Individual Onboarding</div>,
    },
    {
      key: "2",
      label: <div onClick={importEmployeeModal}>Bulk Onboarding</div>,
    },

    // {
    //   key: "3",
    //   label: <div onClick={bulkInvite}>Resend Invite to all Employees</div>,
    // },
    // {
    //   key: "4",
    //   label: (
    //     <div onClick={requestPaymentMethodBulk}>
    //       Request Payment Method from all Employee
    //     </div>
    //   ),
    // },
  ];

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmitExcel = (e) => {
    e.preventDefault();
    if (selectedFiles) {
      setLoadExcel(true);
      let formData = new FormData(); //formdata object
      formData.append("file", selectedFiles); //append the values with key, value pair
      formData.append("employerId", sessionStorage.getItem("employer_id"));
      axios
        .post(`${config.baseUrl}/v1/employees/bulk-onboarding`, formData, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadExcel(false);
          if (res.data.status) {
            setSuccess(true);
            setShowUploadres(true);
            setSelectedFiles(null);
            cancelAddEmployee();
            setFailedUpload(res.data.result.failed);
            setSuccessUpload(res.data.result.success);
            setActiveTab("1");
            getEmployee();
            setAddEmployee(false);
            setOpenEmployee(false);
            setImportEmployee(false);
            setValidUpload(res.data.result.valid);
          } else {
            setActiveTab("3");
            setFailedUpload(res.data.result.failed);
            setSuccessUpload(res.data.result.success);
            setValidUpload(res.data.result.valid);
            setShowUploadres(true);
            setAddEmployee(false);
            setOpenEmployee(false);
            setImportEmployee(false);
          }
        })
        .catch((err) => {
          setLoadExcel(false);
          if (err) {
            setLoad(false);
            setServerError(true);
            Notification("error", "Error", err.response?.data.message);
          }
        });
    } else {
      Notification("error", "Error", "Uploaded File is required");
    }
  };

  const { TabPane } = Tabs;

  function callback(key) {}

  const handleChangePin = (pin) => setPin(pin);

  const [addEmployee, setAddEmployee] = useState(false);
  const [openEmployee, setOpenEmployee] = useState(false);
  const [importEmployee, setImportEmployee] = useState(false);

  const [salaryCurrency, setSalaryCurrency] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");

  const [bulkErrorMessagesLists, setBulkErrorMessagesSalary] = useState([]);
  const [openBulkError, setOpenBulkError] = useState(false);
  const [userChecklists, setUserChecklist] = useState({});

  const [deleteSuccess, setDelete] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [filterModal, setShowFilterModal] = useState(false);

  const [onboardChecklist, setOnboardChecklist] = useState(false);

  const onCancelOnboardChecklist = () => {
    setOnboardChecklist(false);
  };

  const getChecklists = () => {
    setLoadEmployees(true);
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadEmployees(false);
        if (res.data.status) {
          setUserChecklist(res.data.result);
          if (
            !res.data.result?.contactVerified ||
            (!res.data?.result?.businessVerified && employeeData?.length === 0)
          ) {
            setOnboardChecklist(true);
            return;
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployees(false);
        if (err) {
        }
      });
  };

  const [rowId, setRowID] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const showDrawer = (record) => {
    setVisibleDraw(true);
    setRowID(record.id);
    setEmployeeStatus(record.status);
    sessionStorage.setItem("employeeID", record.id);
  };

  const closeDrawer = () => setVisibleDraw(false);

  const onChangeDateOfReg = (date, dateString) => {
    setDobReg(dateString);
  };

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [levelDatas, setEmployeeLevelData] = useState([]);
  const [amountPayStructure, setAmountUpdatePaystructure] = useState("");
  const [load, setLoad] = useState(false);
  const [loading, currencyDatas] = useHttp(
    `${config.baseUrl}/v1/currencies/filtered`,
    []
  );

  const [loadingGender, genderData] = useHttp(
    `${config.baseUrl}/v1/options/GENDER`,
    []
  );

  const [loadingMarital, maritalData] = useHttp(
    `${config.baseUrl}/v1/options/MARITAL_STATUS`,
    []
  );

  const [loadingEmployeeType, employeeTypeData] = useHttp(
    `${config.baseUrl}/v1/options/EMPLOYEE_TYPE`,
    []
  );

  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  const [personalInfo, setPersonalInfo] = useState(true);
  const [enableAll, setEnableAll] = useState(false);
  const [workInfo, setWorkInfo] = useState(false);
  const [paymentInfo, setPayment] = useState(false);
  const [msg, setMsg] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");

  const [firstName, setFirstName] = useState("");
  const [otherName, setOtherName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [gender, setGender] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [annualGrossery, setAnnualGrossery] = useState("");
  const [rowToUpdate, setRowtoUpdate] = useState({});
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [cryptoCurrency, setCryptoCurrency] = useState("");
  const [taxStateData, setTaxData] = useState([]);
  const [dob, setDob] = useState("");
  const [department, setDepartment] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [nok, setNok] = useState("");
  const [nokA, setNokA] = useState("");
  const [taxState, setTaxState] = useState("");
  const [nokPhone, setNokPhone] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [moneyData, setMoneyData] = useState([]);
  const [pfa, setPFA] = useState("");
  const [pfaPin, setPfaPin] = useState("");
  const [position, setPosition] = useState("");
  const [taxid, setTaxId] = useState("");
  const [country, setCountry] = useState("");
  const [paygroup, setPayGroup] = useState([]);
  const [workEmail, setWorkEmail] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [pfaData, setPfaData] = useState([]);
  const [cryptoData, setCryptoData] = useState([]);
  const [paymentTypeData, setPaymentTypeData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [paygroupData, setPaygroupData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const [name, setName] = useState("");
  const [bankApiSuccess, setBankApiSuccess] = useState(false);
  const [bankApiSuccessMsg, setBankApiSuccessMsg] = useState("");
  const [bankApiError, setBankApiError] = useState(false);
  const [bankApiErrorMsg, setBankApiErrorMsg] = useState("");
  const [bankSwitch, setBankSwitch] = useState(false);
  const [mobileMoneySwitch, setMobileMoneySwitch] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const [currencyCode, setCurrencyCode] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const [accountName, setAccountName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [level, setLevel] = useState("");
  const [bankError, setBankError] = useState(false);
  const [cryptoPlatform, setPlatform] = useState("");
  const [cryptoEmail, setCryptoEmail] = useState("");
  const [crypto, setCrypto] = useState("");
  const [payLevelDatas, setPayLevelData] = useState([]);
  const [payLevel, setPayLevel] = useState("");
  const [payScaleData, setPayScalesData] = useState([]);

  const cancelAddEmployee = () => {
    setAddEmployee(false);
    setOpenEmployee(false);
    setAccountNumber("");
    setAnnualGrossery("");
    setBankCode("");
    setContactAddress("");
    setCountry("");
    setCryptoAddress("");
    setPersonalEmail("");
    setShowNetCompute(false);
    setCryptoCurrency("");
    setDob("");
    setDepartment("");
    setEmployeeCode("");
    setEmployeeType("");
    setFirstName("");
    setGender("");
    setHireDate("");
    setLastName("");
    setMaritalStatus("");
    setMobile("");
    setNok("");
    setNokA("");
    setAccountNum("");
    setNokPhone("");
    setOtherName("");
    setPaymentMethod("");
    setPFA("");
    setPersonalInfo(true);
    setWorkInfo(false);
    setPayment(false);
    setPfaPin("");
    setPosition("");
    setTaxId("");
    setAccountName("");
    setValue("accountNum", "");
    setCountryOnboard("");
    setWorkEmail("");
    setValue("countryChoose", "");
    setValue("salaryCurrency", "");
    setSalaryCurrency("");
    setValue("bankCode", "");
    setPayGroup("");
    setBankCode("");
  };

  const gotoWork = () => {
    if (Object.keys(errors).length === 0) {
      setPersonalInfo(false);
      setWorkInfo(true);
      setPayment(false);
    }
  };

  const gotoPersonal = () => {
    setPersonalInfo(true);
    setWorkInfo(false);
    setPayment(false);
    setValue("address", "");
    setValue("addressKin", "");
  };

  const gotoPayment = () => {
    if (Object.keys(errors).length === 0) {
      setPersonalInfo(false);
      setWorkInfo(false);
      setPayment(true);
    }
  };

  const completeOnboarding = () => {
    addEmployeeFunc();
  };

  const backtoWork = () => {
    setPersonalInfo(false);
    setWorkInfo(true);
    setPayment(false);
  };

  const onChange = (date, dateString) => {
    setDob(date);
    setValue("dob", date);
  };

  const onChangeHireDate = (date, dateString) => {
    setHireDate(date);
    setValue("hireDate", date);
  };

  const refresh = () => {
    getEmployee();
  };

  const fetchAccName = (data, newInput) => {
    setLoadBank(true);
    if (newInput) {
      setPaymentMethodLists((prevState) =>
        prevState.map((item) =>
          item.id === data?.id ? { ...item, computeStarted: true } : item
        )
      );
    }
    axios
      .post(
        `${config.baseUrl}/v1/financial-institution/account-query`,
        {
          accountBank: !data?.id ? bankCode : data.institutionCode,
          accountNumber: !newInput ? accountNum : newInput,
          countryCode: !data?.id ? paymentCountry : data?.country,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadBank(false);
        if (res.data.status) {
          setAccountName(res.data.result.accountName);
          setBankApiSuccessMsg(res.data.message);
          if (data?.id) {
            setPaymentMethodLists((prevState) =>
              prevState.map((item) =>
                item.id === data?.id
                  ? {
                      ...item,
                      accountName: res.data.result.accountName,
                      computeStarted: false,
                    }
                  : item
              )
            );
          }
          setTimeout(() => {
            setBankApiSuccess(false);
          }, 1000);
        } else {
          Notification("error", "Error", res.data.message);
          if (data?.id) {
            setPaymentMethodLists((prevState) =>
              prevState.map((item) =>
                item.id === data?.id ? { ...item, computeStarted: false } : item
              )
            );
          }
          setTimeout(() => {
            setBankApiError(false);
          }, 1000);
        }
      })
      .catch((err) => {
        if (err) {
          setLoadBank(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const fetchAccNameMobileMoney = (e) => {
    e.preventDefault();
    setAccountNum(e.target.value);
    const data = {
      accountNumber: accountNum,
      bankCode: bankCode,
      accountName,
      countryCode: country,
      cryptoCurrencyCode: null,
      cryptoWallet: null,
      cryptoWalletEmail: null,
      currencyCode,
      level,
      paymentOption: paymentOption,
    };

    if (data.accountNumber && data.bankCode && data.countryCode) {
      setLoadBank(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: bankCode,
            accountNumber: accountNum,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadBank(false);
          if (res.data.status) {
            setAccountName(res.data.result.accountName);
            setBankApiSuccess(true);
            setBankApiSuccessMsg(res.data.message);
            setTimeout(() => {
              setBankApiSuccess(false);
            }, 1000);
          } else {
            setBankApiError(true);
            setBankApiErrorMsg(res.data.message);
            setTimeout(() => {
              setBankApiError(false);
            }, 1000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoadBank(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setMobileMoneyError(true);
      setTimeout(() => {
        setMobileMoneyError(false);
      }, 2500);
    }
  };

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getBanks = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setBankData(res.data.result);
          setBankLists(
            res.data?.result?.map((d) => ({
              value: d.bankCode,
              label: d.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const countryChange = (e) => {
    setPaymentCountry(e.target.value);
    if (e.target.value) {
      setCountryOnboard(e.target.value);
      getPfa(e.target.value);
      getTaxStates(e.target.value);
      getMoniesByCountry(e.target.value);
      getBanksByCountry(e.target.value);
      getPaygroup(e.target.value);
    }
  };

  const getBanksByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setBankData(res.data.result);
          setBankLists(
            res.data?.result?.map((d) => ({
              value: d.bankCode,
              label: d.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getMoniesByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/mobile-monies/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setMoneyData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closeModal = () => {
    setVisible(false);
  };

  const loadAllCrus = () => {
    getDepartment();
    getPosition();
    getLevelsData();
  };

  const getCrypto = () => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/crypto`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCryptoData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const deleteEmployee = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this Employee`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteEmployeeFunc();
      },
      onCancel() {},
    });
  };

  const deleteEmployeeFunc = () => {
    axios
      .delete(`${config.baseUrl}/v1/employees/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDelete(true);
          setVisibleDraw(false);
          Notification("success", "Success", res.data.message);
          getEmployee();
          setTimeout(() => {
            setDelete(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const viewErrorMsg = (errMsg, e) => {
    e.preventDefault();
    setFailedErrMessages(errMsg.messages);
    setFailedMsgModal(true);
  };

  const resendInvite = () => {
    Modal.confirm({
      title: "Are you sure you want to resend Invite to this employee?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      onOk() {
        axios
          .get(`${config.baseUrl}/v1/employees/resend/single/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setOnSuccess(true);
              Notification("success", "Success", res.data.message);
              getEmployee();
              setVisibleDraw(false);
              setTimeout(() => {
                setOnSuccess(false);
              }, 2000);
            } else {
              setOnSuccess(true);
              Notification("error", "Error", res.data.message);
              getEmployee();
              setVisibleDraw(false);
              setTimeout(() => {
                setOnSuccess(false);
              }, 2000);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const closeLookupModal = () => {
    setOpenLookUpKycModal(false);
  };

  const downloadBulkTemplate = (e) => {
    e.preventDefault();
    setLoad(true);
    downloadTemplate();
  };

  const downloadTemplate = () => {
    setDownloadTemp(true);
    fetch(
      `${
        config.baseUrl
      }/v1/employees/download-template/${sessionStorage.getItem(
        "employer_id"
      )}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }),
      }
    ).then((response) => {
      setDownloadTemp(false);
      const filename = "bulk-onboarding.xlsx";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  const setPaymentFunc = (e) => {
    setPaymentOption(e.target.value);
    if (e.target.value === "BANK_ACCOUNT") {
      setBankSwitch(true);
      setMobileMoneySwitch(false);
    } else if (e.target.value === "MOBILE_MONEY") {
      setMobileMoneySwitch(true);
      setBankSwitch(false);
    } else {
      setMobileMoneySwitch(false);
      setBankSwitch(false);
    }
  };

  const cancelSetKyc = () => {
    setKycModal(false);
  };

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  const requestPaymentMethod = () => {
    Modal.confirm({
      title:
        "Are you sure you want to request payment method for this employee?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      onOk() {
        axios
          .get(`${config.baseUrl}/v1/payment-methods/request/single/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setOnSuccess(true);
              Notification("success", "Success", res.data.message);

              getEmployee();
              setVisibleDraw(false);
              setTimeout(() => {
                setOnSuccess(false);
              }, 2000);
            } else {
              setOnSuccess(true);
              Notification("error", "Error", res.data.message);
              getEmployee();
              setVisibleDraw(false);
              setTimeout(() => {
                setOnSuccess(false);
              }, 2000);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const handleBusinesskyc = () => {
    const data = {
      industry,
      nhfNumber,
      ownership,
      penComCode,
      address,
      registerType,
      size,
      businessAddress: address,
      supportEmail,
      taxId: kyctaxId,
      website,
    };
    axios
      .put(
        `${
          config.baseUrl
        }/v1/employers/business-detail/${sessionStorage.getItem(
          "employer_id"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          getContactDetails();
          setKycModal(false);
          getChecklists();
          setOpenLookUpKycModal(false);
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const verifyDetails = (type) => {
    if (pin || dob) {
      setLoad(true);
      axios
        .get(
          `${config.baseUrl}/v1/kyc-details/${
            type === "personal"
              ? "verifyPersonalDetail"
              : "verifyBusinessDetail"
          }?identity=${
            type === "personal" ? personalIdentity : businessIdentity
          }&type=${
            type === "business"
              ? selectedBusinessdentityType
              : selectedIdentityType
          }&verifyBy=${
            confirmationResult.verifyBy === "OTP" ? pin : dobReg
          }&kycId=${kycId}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            if (type === "personal") {
              handlePersonalKyc();
            } else {
              handleBusinesskyc();
            }
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoad(false);
          if (err) {
            Notification("error", "Error", "Failed to connect to SalarioPay");
          }
        });
    }
  };

  const tabSwicth = [
    {
      key: "1",
      label: `Success Records`,
      children: ``,
    },
    {
      key: "2",
      label: `Valid Records`,
      children: ``,
    },

    {
      key: "3",
      label: `Failed Records`,
      children: ``,
    },
  ];

  const changeStatus = () => {
    Modal.confirm({
      title: "Are you sure you want to change this employee status?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/employees/status/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setOnSuccess(true);
              Notification("success", "Success", res.data.message);
              getEmployee();
              setVisibleDraw(false);
              setTimeout(() => {
                setDelete(false);
              }, 2000);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const getPaymentType = () => {
    axios
      .get(`${config.baseUrl}/v1/payment-options/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPaymentTypeData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/pfa/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const arr = res.data.result;
          const index = 8;
          const value = {
            createdAt: "2024-06-05T07:08:57.837+00:00",
            updatedAt: "2024-06-05T07:08:57.837+00:00",
            id: null,
            country: "NG",
            pfaCode: "Select",
            pfaName: "NONE",
          };
          const newArr = [];
          for (let i = 0; i < arr.length; i++) {
            if (i === index) {
              newArr.push(value);
            }
            newArr.push(arr[i]);
          }

          setPfaData(newArr);
          setPfaOptions(
            newArr?.map((d) => ({
              value: d?.pfaCode,
              label: d?.pfaName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getTaxStates = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTaxData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPaygroupList = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupDataList(
            res.data.result?.map((item) => ({
              value: item?.id,
              label: item?.groupName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getPaygroupList();
  }, []);

  const cryptoCurrencyChange = (e) => {
    const getName = cryptoData.find((v) => v.code === e.target.value);
    setCrypto(getName.name);
    setCurrencyCode(e.target.value);
  };

  function onChangeStartDate(date, dateString) {
    setStartDateFilter(date);
  }
  function onChangeEndDate(date, dateString) {
    setEndDateFilter(date);
  }

  const getPaygroup = (country) => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPayLevel = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-levels/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayLevelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPayScales = (id) => {
    setPayLevel(id);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-scales/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&payLevelId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayScalesData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPosition = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionData(res.data.result);
          setPositionOptions(
            res.data.result?.map((d) => ({
              value: d?.name,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getDepartment = () => {
    axios
      .get(
        `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDepartmentData(res.data.result);
          setDepartmentOptions(
            res.data.result?.map((d) => ({
              value: d?.name,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    const updatedData = employeeData.map((item) => ({ ...item, checked }));
    setEmployeeData(updatedData);
    setListCheck(updatedData);
  };

  const handleCheckboxChange = (id) => {
    const updatedData = employeeData.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setEmployeeData(updatedData);
    setListCheck(updatedData?.filter((item) => item?.checked));
    const allChecked = updatedData.every((item) => item.checked);
    setSelectAll(allChecked);
  };

  const searchEmployee = (val) => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&search=${val}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.status) {
          const employeDatas = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          settotalData(res.data.totalElements);
          setEmployeeData((prevState) => {
            return employeDatas.map((data) => ({
              employeeCode: data.employeeCode,
              firstName: data.firstName,
              lastName: data.lastName,
              otherName: data.otherName,
              user_id: data.userId,
              checked: false,
              created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
              mobile: data.mobile,
              paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
              workEmail: data.workEmail,
              id: data.id,
              salary: data.monthlyNetSalary
                ? data.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
                : "",
              status: data.status ? "ACTIVE" : "INACTIVE",
              personalEmail: data?.personalEmail,
            }));
          });
        }
      })

      .catch((err) => {
        if (err) {
        }
      });
  };

  const getEmployeeSalaryUpdatesList = () => {
    setPage(1);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&status=active`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const employeDatas = res.data.result;
        setTotalItemSalaryUpdate(res.data.totalPages * 10);
        setDataEmployeeSalaryUpdate(
          employeDatas.map((data) => ({
            employeeCode: data.employeeCode,
            firstName: data.firstName,
            computeStarted: false,
            lastName: data.lastName,
            paygroup: data?.payGroupId,
            otherName: data.otherName,
            loadSalaryUpdate: false,
            monthlyNetCompute: data?.monthlyNetSalary,
            checkedValues: [
              data?.computePension ? "Pension" : "",
              data?.computeTax ? "Tax" : "",
            ],
            user_id: data.userId,
            annualGrosserySalaryUpdate: formatNumber(data?.annualGrossSalary),
            employeeType: data?.employeeType,
            checked: false,
            prorateType: data?.proRateType,
            prorateValue: data?.proRateType?.includes("AMOUNT")
              ? formatNumber(data?.proRateValue)
              : data?.proRateValue,
            created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
            mobile: data.mobile,
            paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
            workEmail: data.workEmail,
            editedStatus: false,
            id: data.id,
            country: data?.country,
            currency: data?.salaryCurrency,
            salary: data.monthlyNetSalary
              ? data.salaryCurrency +
                " " +
                Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
              : "",
            status: data.status ? "ACTIVE" : "INACTIVE",
            personalEmail: data?.personalEmail,
          }))
        );
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPagedEmployeePaymentsMethods = (query) => {
    setPinModal(false);
    axios
      .get(`${config.baseUrl}/v1/employees/payment-method${query}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadEmployeesPayment(false);
        setTotalItemsPaymentMethods(res.data.totalPages * 10);
        setPaymentMethodLists(
          res.data?.result?.map((data) => ({
            firstName: data.firstName,
            lastName: data.lastName,
            employeeCode: data.employeeCode,
            paymentMethods: data.paymentMethods,
            accountName: "",
            employeeId: data?.id,
            loadSalaryUpdate: false,
            id: data?.id,
            accountNum: "",
            default: data?.isDefault,
            editedStatus: false,
            paymentMethodId: "New",
            computeStarted: false,
            country: "country",
            currencyCode: "currency",
            institutionCode: "Select Payment Bank",
          }))
        );
      })
      .catch((err) => {
        setLoadEmployeesPayment(false);
        if (err) {
        }
      });
  };

  const getEmployeePaymentMethods = () => {
    setPagePaymentMethod(1);
    setLoadEmployeesPayment(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/payment-method/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployeesPayment(false);
        setTotalItemsPaymentMethods(res.data.totalPages * 10);
        setPaymentMethodLists(
          res.data?.result?.map((data) => ({
            firstName: data.firstName,
            lastName: data.lastName,
            employeeCode: data.employeeCode,
            paymentMethods: data.paymentMethods,
            accountName: "",
            employeeId: data?.id,
            loadSalaryUpdate: false,
            id: data?.id,
            accountNum: "",
            default: data?.isDefault,
            editedStatus: false,
            paymentMethodId: "New",
            computeStarted: false,
            country: "Select Payment Country",
            currencyCode: "Select Payment Currency",
            institutionCode: "Select Payment Bank",
          }))
        );
      })
      .catch((err) => {
        setLoadEmployeesPayment(false);
        if (err) {
        }
      });
  };

  const getEmployee = () => {
    setPage(1);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&status=active`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const employeDatas = res.data.result;
        setTotalItems(res.data.totalPages * 10);
        settotalData(res.data.totalElements);
        setEmployeeData(
          employeDatas.map((data) => ({
            employeeCode: data.employeeCode,
            firstName: data.firstName,
            lastName: data.lastName,
            otherName: data.otherName,
            user_id: data.userId,
            checked: false,
            created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
            mobile: data.mobile,
            paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
            workEmail: data.workEmail,
            id: data.id,
            salary: data.monthlyNetSalary
              ? data.salaryCurrency +
                " " +
                Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
              : "",
            status: data.status ? "ACTIVE" : "INACTIVE",
            personalEmail: data?.personalEmail,
          }))
        );
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getEmployeeFilter = (val) => {
    setLoadEmployees(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/paged?page=0&size=10&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&status=${val}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployees(false);
        if (res.data.status) {
          const employeDatas = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          settotalData(res.data.totalElements);
          setEmployeeData(
            employeDatas.map((data) => ({
              employeeCode: data.employeeCode,
              firstName: data.firstName,
              lastName: data.lastName,
              otherName: data.otherName,
              user_id: data.userId,
              checked: false,
              created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
              mobile: data.mobile,
              paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
              workEmail: data.workEmail,
              id: data.id,
              salary: data.monthlyNetSalary
                ? data.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
                : "",
              status: data.status ? "ACTIVE" : "INACTIVE",
              personalEmail: data?.personalEmail,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployees(false);
        if (err) {
        }
      });
  };

  const getBankName = (id) => {
    var bank = bankData?.find((x) => x.bankCode === id);
    return bank?.bankName;
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const addEmployeeFunc = () => {
    const bankAccountPaymentOptionData = {
      accountName: accountName,
      accountIdentifier: accountNum,
      annualGrossSalary: Number(annualGrossery),
      institutionCode: bankCode,
      institutionName: getBankName(bankCode),
      contactAddress: contactAddress,
      country: countryOnboard,
      paymentCurrency: currencyCode,
      dateOfBirth: dob,
      department: department === "Select" ? "" : department,
      employeeType: employeeType,
      firstName: firstName,
      level: level === "Select" ? "" : level,
      payLevelId: payLevel === "Select" ? "" : payLevel,
      payScaleId: payScale === "Select" ? "" : payScale,
      gender: gender,
      hireDate: hireDate,
      computePension: checkWorkOptions?.includes("Pension"),
      computeTax: checkWorkOptions?.includes("Tax"),
      lastName: lastName,
      maritalStatus,
      mobile: mobile,
      nextOfKin: nok,
      nextOfKinAddress: nokA,
      bvn: employeeBvn,
      accountOption: "EXTERNAL_ACCOUNT",
      nextOfKinPhone: nokPhone,
      otherName: otherName,
      payGroupId: employeeType === "FULL-TIME" ? paygroup : "",
      paymentCountry: paymentCountry,
      paymentOption,
      pfaCode: pfa,
      taxState: taxState,
      pfaName: pfa,
      pfaRsaPin: pfaPin,
      position: position === "Select" ? "" : position,
      personalEmail,
      salaryCurrency: salaryCurrency,
      taxState: taxState,
      employerId: sessionStorage.getItem("employer_id"),
      tin: taxid,
      workEmail: workEmail,
    };

    const cryptoWalletPaymentOptionData = {
      annualGrossSalary: annualGrossery,
      contactAddress: contactAddress,
      country: countryOnboard,
      cryptoName: cryptoCurrency,
      cryptoWallet: cryptoPlatform,
      cryptoWalletEmail: cryptoEmail,
      currencyCode,
      dateOfBirth: dob,
      department: department,
      employeeCode: employeeCode,
      employeeType: employeeType,
      firstName: firstName,
      gender: gender,
      hireDate: hireDate,
      level: level,
      lastName: lastName,
      maritalStatus,
      mobile: mobile,
      nextOfKin: nok,
      nextOfKinAddress: nokA,
      nextOfKinPhone: nokPhone,
      otherName: otherName,
      payGroup: paygroup,
      paymentOption,
      pfaCode: pfa,
      pfaName: pfa,
      pfaRsaPin: pfaPin,
      position: position,
      salaryCurrency: salaryCurrency,
      employerId: sessionStorage.getItem("employer_id"),
      tin: taxid,
      tinState: taxState,
      workEmail: workEmail,
    };
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/employees/single-onboarding`,
        paymentOption === "BANK_ACCOUNT"
          ? bankAccountPaymentOptionData
          : cryptoWalletPaymentOptionData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setAccountNumber("");
          setAnnualGrossery("");
          setBankCode("");
          setContactAddress("");
          setCountry("");
          setCryptoAddress("");
          setCryptoCurrency("");
          setPersonalInfo(true);
          setWorkInfo(false);
          setPayment(false);
          setDob("");
          setDepartment("");
          setEmployeeCode("");
          setEmployeeType("");
          setFirstName("");
          setLevel("");
          setPersonalEmail("");
          setGender("");
          setHireDate("");
          setLastName("");
          setMaritalStatus("");
          setMobile("");
          setNok("");
          setNokA("");
          setNokPhone("");
          setOtherName("");
          setPaymentMethod("");
          setPFA("");
          setPfaPin("");
          setPosition("");
          setTaxId("");
          setWorkEmail("");
          cancelAddEmployee();
          const clearObject = Object.fromEntries(
            Object.keys(getValues()).map((key) => [key, null])
          );
          reset(clearObject);
          getEmployee();
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          Notification("error", "Error", "Failed to connect to salario pay");
        }
      });
  };

  const getMonthlyNetSalary = () => {
    setShowNetCompute(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/pay-structure?annualGrossSalary=${annualGrossery}&computeNHF=false&computePension=${checkWorkOptions?.includes(
          "Pension"
        )}&computeTax=${checkWorkOptions?.includes(
          "Tax"
        )}&country=${countryOnboard}&currency=${salaryCurrency}&employeeType=${employeeType}&employerId=${sessionStorage?.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setMonthltyNetSalaryValue(res.data.result.monthlyNetSalary);
          var arr = salaryEmployeeDataUpdate;
          let objIndex = salaryEmployeeDataUpdate.findIndex(
            (obj) => obj.id == currentSalaryUpdateId
          );
          arr[objIndex].monthlyNetCompute = res.data.result.monthlyNetSalary;
          setDataEmployeeSalaryUpdate(arr);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getLevelsData = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setEmployeeLevelData(res.data.result);
          setLevelOptions(
            res.data.result?.map((d) => ({
              value: d?.level,
              label: d?.level,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (!selectAll) {
      setListCheck([]);
    }
  }, [selectAll]);

  useEffect(() => {
    if (annualGrossery) {
      getMonthlyNetSalary();
    }
  }, [
    annualGrossery,
    checkWorkOptions,
    salaryCurrency,
    employeeType,
    countryOnboard,
  ]);

  useEffect(() => {
    getCounties();
    getPaymentType();
    getLevelsData();
    getChecklists();
    getCrypto();
    getEmployee();
    getPayLevel();
    getEmployeePaymentMethods();
    getDepartment();
    getContactDetails();
    getPosition();
    getEmployeeSalaryUpdatesList();
    setFilteringPagination(false);
  }, []);

  const submitForm = () => {
    setIsSubmitting(true);
  };

  const onDeleteMultipleEmployees = () => {
    Modal.confirm({
      title: `Are you sure delete these Employee(s)`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/employees`,
            {
              employeeIds: listChecks?.map((item) => {
                return item.id;
              }),
            },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              getEmployee();
              setListCheck([]);
              setSelectAll(false);
              Notification("success", "Success", res.data.message);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const onPopulateChecks = (e) => {
    if (e.target?.checked) {
      setListCheck([...listChecks, e.target.value]);
    } else {
      setListCheck(listChecks?.filter((list) => list !== e.target?.value));
    }
  };

  const convertReadableDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  const onFilerSubmit = () => {
    setLoadFilter(true);
    axios
      .post(
        `${config.baseUrl}/v1/employees/filtered/paged?size=10&page=0`,
        {
          department: departmentSelected,
          employeeType: employeeTypeSelected,
          employerId: sessionStorage.getItem("employer_id"),
          endDate: convertReadableDate(endDateFilter),
          level: employeeLevelSelected,
          position: positionSelected,
          startDate: convertReadableDate(startDateFilter),
          status: statusFilter,
        },

        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadFilter(false);
        setOpenFilterModal(false);
        const employeDatas = res.data.result;
        setTotalItems(res.data.totalPages * 10);
        settotalData(res.data.totalElements);
        setEmployeeData(
          employeDatas.map((data) => ({
            employeeCode: data.employeeCode,
            firstName: data.firstName,
            lastName: data.lastName,
            otherName: data.otherName,
            user_id: data.userId,
            checked: false,
            created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
            mobile: data.mobile,
            paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
            workEmail: data.workEmail,
            id: data.id,
            salary: data.monthlyNetSalary
              ? data.salaryCurrency +
                " " +
                Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
              : "",
            status: data.status ? "ACTIVE" : "INACTIVE",
            personalEmail: data?.personalEmail,
          }))
        );
      })
      .catch((err) => {
        setLoadFilter(false);
        if (err) {
        }
      });
  };
  const columns = [
    {
      key: "i",
      text: "",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <div class="form-check">
              <input
                class="form-check-input border-checks"
                type="checkbox"
                checked={record.checked}
                onChange={() => handleCheckboxChange(record.id)}
              />
            </div>
          </Fragment>
        );
      },
    },
    {
      key: "employeeCode",
      text: "Employee Code",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "created_date",
      text: "Date Created",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "firstName",
      text: "First Name",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "lastName",
      text: "Last Name",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "salary",
      text: "Net Salary",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },
    {
      key: "status",
      text: "Status",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            <span
              className={
                {
                  ACTIVE: "badge bg-success",
                  COMPLETED: "badge bg-success",
                  SUCCESS: "badge bg-success",
                  FAILED: "badge bg-danger",
                  IDLE: "badge bg-info",
                  PENDING: "badge bg-warning",
                  PROCESSING: "badge bg-warning",
                  INACTIVE: "badge bg-danger",
                }[record.status]
              }
            >
              {record.status}
            </span>
          </Fragment>
        );
      },
    },
    {
      key: "paymentMethod",
      text: "Payment Method",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const countryOptions = countryData.map((countries) => {
    return (
      <option value={countries.code} key={countries.code}>
        {countries.name}
      </option>
    );
  });

  const cryptoOptions = cryptoData.map((crypto) => {
    return (
      <option value={crypto.code} key={crypto.id}>
        {crypto.name}
      </option>
    );
  });

  const paymentOptions = paymentTypeData.map((payment) => {
    return (
      <option value={payment.code} key={payment.id}>
        {payment.name}
      </option>
    );
  });

  const departmentOptions = departmentData.map((department) => {
    return (
      <option value={department.name} key={department.id}>
        {department.name}
      </option>
    );
  });
  const positionptions = positionData.map((position) => {
    return (
      <option value={position.name} key={position.id}>
        {position.name}
      </option>
    );
  });

  const paygroupOptions = paygroupData.map((paygroup) => {
    return (
      <option value={paygroup.code} key={paygroup.id}>
        {paygroup.groupName}
      </option>
    );
  });

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.currencyCode} key={currency.currencyCode}>
        {currency.currencyName}
      </option>
    );
  });

  const bankOptions = bankData.map((bank) => {
    return (
      <option value={bank.bankCode} key={bank.bankCode}>
        {bank.bankName}
      </option>
    );
  });

  const moneyOptions = moneyData.map((bank) => {
    return (
      <option value={bank.code} key={bank.code}>
        {bank.name}
      </option>
    );
  });

  const customSort = (records) => {
    setPage(1);
    setFilterValue(records?.filter_value);
    searchEmployee(records?.filter_value);
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_info: true,
    show_pagination: false,
    show_length_menu: false,
    button: {
      excel: true,
      print: true,
    },
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }
  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {},
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {},
      onDoubleClick: (event) => {},
    },
  ];

  const paginationSalaryUpdate = (page, pageSize) => {
    setPageSalaryUpdate(page);
    handleSubmitSalaryUpdateBulk("pagination");
    const queryString = `?page=${
      page - 1
    }&size=10&employerId=${sessionStorage.getItem("employer_id")}&status=`;
    getPagedSalaryUpdateList(queryString);
  };

  const paginationPaymentMethods = (page, pageSize) => {
    setPagePaymentMethod(page);
    handleSubmitPaymentMethodUpdateBulk("pagination");
    const queryString = `/paged?page=${
      page - 1
    }&size=10&employerId=${sessionStorage.getItem("employer_id")}&status=`;
    getPagedEmployeePaymentsMethods(queryString);
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const filterPaged = `?page=${
      page - 1
    }&size=${pageSize}&search=${filterValue}`;
    const queryString = `?page=${
      page - 1
    }&size=${pageSize}&employerId=${sessionStorage.getItem(
      "employer_id"
    )}&status=${statusFilter}&startDate=${startDate}&endDate=${endDate}&search=${filterValue}`;
    !filteringPagination ? getPaged(queryString) : getPagedFilter(filterPaged);
  };

  const getConfirmation = (typeKyc, e) => {
    const dataPersonal = {
      companyName: "",
      country: country,
      identity: personalIdentity,
      type: selectedIdentityType,
      firstName: kycfirstName,
      lastName: kyclastName,
      dateOfBirth: kycdob,
    };
    const dataBusiness = {
      companyName: businessName,
      country: countryBusiness,
      identity: businessIdentity,
      type: selectedBusinessdentityType,
    };
    if (typeKyc === "personal") {
      if (
        dataPersonal.country &&
        dataPersonal.identity &&
        dataPersonal.type &&
        dataPersonal.firstName &&
        kycotherName &&
        dataPersonal.lastName &&
        dataPersonal.dateOfBirth &&
        kyctitle &&
        kycposition &&
        kycphoneNumber &&
        kycgender &&
        kycmaritalStatus
      ) {
        setLoad(true);
        confirmPersonal(dataPersonal);
      }
    } else {
      if (
        dataBusiness.companyName &&
        dataPersonal.country &&
        dataPersonal.type &&
        dataPersonal.identity &&
        industry &&
        size &&
        website &&
        registerType &&
        supportEmail &&
        ownership
      ) {
        setLoad(true);
        confirmBusiness(dataBusiness);
      }
    }
  };

  const cancelUploadRes = () => {
    setShowUploadres(false);
  };

  const getPersonalKyc = (country) => {
    setCountry(country);
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/personalKycTypes?country=${
          country ? country : countryOnboard
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setIdentityType(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const setPersonalKycIdentityType = (e) => {
    setSelectedIdentity(e.target.value);
    const getIdentityTypeDetails = identityType.find(
      (identity) => identity.type === e.target.value
    );
    setIdentityDetails(getIdentityTypeDetails.label);
  };

  const setBusinessKycIdentityType = (e) => {
    setSelectedBusinessIdentityType(e.target.value);
    const getIdentityTypeDetails = businessIdentityType.find(
      (identity) => identity.type === e.target.value
    );
    setBusinessIdentityDetails(getIdentityTypeDetails.label);
  };

  const completeInformation = () => {
    setKycModal(true);
  };

  const confirmPersonalKyc = () => {
    const data = {
      companyName: null,
      country: countryOnboard,
      dateOfBirth: dob,
      firstName: firstName,
      identity: employeeBvn,
      lastName: lastName,
      state: null,
      type: getBvnType(),
    };
    if (data.firstName && data.identity) {
      setLoadVerify(true);
      confirmPersonalKycOnboarding(data);
    }
  };

  const confirmPersonalKycOnboarding = (req) => {
    const data = {
      companyName: null,
      country: "NG",
      dateOfBirth: dob,
      firstName: firstName,
      identity: personalIdentity,
      lastName: lastName,
      state: null,
      type: selectedIdentityType,
    };

    axios
      .post(
        `${config.baseUrl}/v1/kyc-details/lookUpKycDetail`,
        req?.identity ? req : data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadVerify(false);
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        setLoadVerify(false);
        if (err) {
        }
      });
  };

  const confirmPersonal = (req) => {
    const data = {
      companyName: null,
      country: "NG",
      dateOfBirth: dob,
      firstName: firstName,
      identity: personalIdentity,
      lastName: lastName,
      state: null,
      type: selectedIdentityType,
    };

    axios
      .post(
        `${config.baseUrl}/v1/kyc-details/lookUpKycDetail`,
        req?.identity ? req : data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadVerify(false);
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          // setMsg(res.data.message);
          // setConfirmationResult(res.data.result);
          // setKycId(res.data.result.id);
          // setOpenLookUpKycModal(true);
          // if (res.data.result.verifyBy === "NONE") {
          //   defaultVerify(
          //     res.data.result.id,
          //     personalIdentity,
          //     selectedIdentityType
          //   );
          // }
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        setLoadVerify(false);
        if (err) {
        }
      });
  };

  const backToPersonalKyc = () => {
    setSteps("personal");
  };

  const defaultVerify = (kycId, identity, type) => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/verifyPersonalDetail?kycId=${kycId}&identity=${identity}&type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          handlePersonalKyc();
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const getContactDetails = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const contactDetails = res.data.result;
          setContactDetails(contactDetails);
          setSize(contactDetails.size);
          setKycTitle(contactDetails.title);
          setKycOthertName(contactDetails.middleName);
          setKycDob(contactDetails.dateOfBirth);
          setKycTaxId(contactDetails.taxId);
          setPenComCode(contactDetails.penComCode);
          setBusinessName(contactDetails.businessName);
          setIndustry(contactDetails.industry);
          setRegisterType(contactDetails.registerType);
          setKycFirstName(contactDetails.firstName);
          setKycLastName(contactDetails.lastName);
          setSupportEmail(contactDetails.supportEmail);
          setAddress(contactDetails.businessAddress);
          setOwnership(contactDetails.ownership);
          setDateOfRegistration(contactDetails.dateOfRegistration);
          setKycGender(contactDetails.gender);
          setKycMaritalStatus(contactDetails.maritalStatus);
          setKycPhoneNumber(contactDetails.phoneNumber);
          setWebsite(contactDetails.website);
          setNhfNumber(contactDetails.nhfNumber);
          setCountry(contactDetails.personalKycCountry);
          setCountryBusiness(contactDetails.businessKycCountry);
          getPersonalKyc(contactDetails.personalKycCountry);
          setKycPosition(contactDetails.position);
          getBusinessKyc(contactDetails.personalKycCountry);
          setSelectedIdentity(contactDetails.personalKycType);
          setPersonalIdentity(contactDetails.personalIdentity);
          setBusinessIdentity(contactDetails.businessIdentity);
          setBusinessIdentityDetails(contactDetails.businessKycType);
          setSelectedBusinessIdentityType(contactDetails.businessKycType);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handlePersonalKyc = () => {
    const data = {
      dateOfBirth: dob,
      gender: kycgender,
      maritalStatus: kycmaritalStatus,
      phoneNumber: kycphoneNumber,
      position: kycposition,
      middleName: kycotherName,
      title: kyctitle,
    };
    setLoad(true);
    axios
      .put(
        `${
          config.baseUrl
        }/v1/employers/personal-detail/${sessionStorage.getItem(
          "employer_id"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setOpenLookUpKycModal(false);
          setSteps("business");
          const contactDetails = res.data.result;
          getContactDetails();
          setKycTitle(contactDetails.title);
          setKycPosition(contactDetails.position);
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const formatNumber = (num) => {
    return "₦" + new Intl.NumberFormat().format(num);
  };

  const onChangeCheckboxManageSalary = (data, checkedValues) => {
    const updatedDataAmount = salaryEmployeeDataUpdate.map((item) =>
      item.id === data?.id
        ? { ...item, checkedValues: checkedValues, editedStatus: true }
        : item
    );
    setDataEmployeeSalaryUpdate(updatedDataAmount);
  };

  const formatCurrency = (num) => {
    // Remove all non-numeric characters except the decimal point
    const numberString = num.replace(/[^\d.]/g, "");

    // Parse as float and format with commas and two decimal places
    const floatValue = parseFloat(numberString);

    if (isNaN(floatValue)) {
      return ""; // Return empty string if the input is not a valid number
    }

    return floatValue.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    });
  };

  const handleInputChangeAccountPayemntMethod = (data, newInput) => {
    console.log(data?.id, console.log(newInput));
    setEnableAllPayment(true);
    const updatedData = paymentMethodLists.map((item) =>
      item.id === data?.id
        ? {
            ...item,
            accountNum: newInput,
            editedStatus: true,
          }
        : item
    );
    setPaymentMethodLists(updatedData);
    if (newInput?.length === 10) {
      fetchAccName(data, newInput);
    }
  };

  const handleInputChangeSalaryUpdate = (data, newInput) => {
    setEnableAll(true);
    const updatedData = salaryEmployeeDataUpdate.map((item) =>
      item.id === data?.id
        ? {
            ...item,
            annualGrosserySalaryUpdate:
              "₦" +
              newInput
                ?.replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            editedStatus: true,
          }
        : item
    );
    setDataEmployeeSalaryUpdate(updatedData);
    setRowtoUpdate(data);
    setAmountUpdatePaystructure(newInput);
  };

  const calculatePaystructure = () => {
    setDataEmployeeSalaryUpdate((prevState) =>
      prevState.map((item) =>
        item.id === rowToUpdate?.id ? { ...item, computeStarted: true } : item
      )
    );

    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/pay-structure?annualGrossSalary=${amountPayStructure?.replace(
          /[₦,]/g,
          ""
        )}&computeNHF=false&computePension=${checkWorkOptions?.includes(
          "Pension"
        )}&computeTax=${checkWorkOptions?.includes("Tax")}&country=${
          rowToUpdate?.country
        }&currency=${rowToUpdate?.currency}&employeeType=${
          rowToUpdate?.employeeType
        }&employerId=${sessionStorage?.getItem("employer_id")}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDataEmployeeSalaryUpdate((prevState) =>
            prevState.map((item) =>
              item.id === rowToUpdate?.id
                ? {
                    ...item,
                    monthlyNetCompute: res.data.result.monthlyNetSalary,
                    computeStarted: false,
                  }
                : item
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    calculatePaystructure();
  }, [amountPayStructure]);

  const parseCurrency = (str) => {
    const cleanedString = str?.replace(/[^0-9.-]+/g, ""); // Remove ₦, commas, and any non-numeric characters
    return parseFloat(cleanedString); // Convert to a float
  };

  const handleSubmitSalaryUpdateBulk = (page) => {
    setLoadUpdateBulk(true);
    console.log(salaryEmployeeDataUpdate);
    const data = salaryEmployeeDataUpdate?.map((data) => ({
      annualGrossSalary: data?.annualGrosserySalaryUpdate?.replace(/[₦,]/g, ""),
      bonusAmount: data?.bonusAmount,
      computeNHF: false,
      computePension: data?.checkedValues?.includes("Pension"),
      computeTax: data?.checkedValues?.includes("Tax"),
      payGroupId: data?.paygroup,
      employeeId: data?.id,
      prorateType: data?.prorateType,
      prorateValue: data?.prorateType?.includes("AMOUNT")
        ? data?.prorateValue?.replace(/[₦,]/g, "")
        : data?.prorateValue,
      firstName: data?.firstName,
      lastName: data?.lastName,
      employeeCode: data?.employeeCode,
      checkedValues: data?.checkedValues,
      monthlyNetSalary: data?.monthlyNetCompute,
      country: data?.country,
      currency: data?.currency,
      employeeType: data?.employeeType,
    }));
    const payload = {
      employerId: sessionStorage?.getItem("employer_id"),
      salaryDetails: data,
    };
    const arr = [];
    axios
      .put(`${config.baseUrl}/v1/employees/salary-detail/bulk`, payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadUpdateBulk(false);
        if (res.data.status) {
          setEnableAll(false);
          for (var i = 0; i < res.data?.result?.length; i++) {
            if (
              payload?.salaryDetails[i]?.employeeId ===
              res.data?.result[i]?.employeeId
            ) {
              console.log("compute ray started");
              arr?.push({
                annualGrossSalary: formatNumber(
                  res.data?.result[i].annualGrossSalary
                ),
                bonusAmount: res.data?.result[i]?.bonusAmount,
                computePension:
                  payload?.salaryDetails[i]?.checkedValues?.includes("Pension"),
                computeTax:
                  payload?.salaryDetails[i]?.checkedValues?.includes("Tax"),
                checkedValues: [
                  res.data?.result[i]?.computePension ? "Pension" : "",
                  res.data?.result[i]?.computeTax ? "Tax" : "",
                ],
                payGroupId: res.data?.result[i]?.payGroupId,
                employeeId: payload?.salaryDetails[i]?.employeeId,
                id: payload?.salaryDetails[i]?.employeeId,
                prorateType: res.data?.result[i]?.prorateType,
                prorateValue: res.data?.result[i]?.prorateType?.includes(
                  "AMOUNT"
                )
                  ? formatNumber(res.data?.result[i]?.prorateValue)
                  : res.data?.result[i]?.prorateValue,
                employeeCode: payload?.salaryDetails[i]?.employeeCode,
                message: res.data?.result[i]?.messages,
                firstName: payload?.salaryDetails[i]?.firstName,
                lastName: payload?.salaryDetails[i]?.lastName,
                monthlyNetCompute: payload?.salaryDetails[i]?.monthlyNetSalary,
                status: res.data?.result[i]?.status,
                editedStatus:
                  res.data?.result[i]?.status === "SUCCESS" ? false : true,
                country: payload?.salaryDetails[i]?.country,
                currency: payload?.salaryDetails[i]?.currency,
                employeeType: payload?.salaryDetails[i]?.employeeType,
              });
            }
          }
          console.log(arr);
          if (!page) {
            setDataEmployeeSalaryUpdate(arr);
            Notification("success", "Success", res.data.message);
          }
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateBulk(false);
        if (err) {
        }
      });
  };

  const handleSubmitPaymentMethodUpdateBulk = () => {
    if (!employerPin) {
      setPinBulk(true);
      setPinModal(true);
      return;
    }
    setPinBulk(false);
    setPinModal(false);
    setLoadUpdateBulk(true);
    var trainedPaymentsMethod = paymentMethodLists?.filter(
      (val) => val.paymentMethodId !== "New"
    );
    const datar = trainedPaymentsMethod?.map((data) => ({
      accountIdentifier: data?.accountNum,
      accountName: data?.accountName,
      id: data?.paymentMethodId,
      countryCode: data?.country,
      currencyCode: data?.currencyCode,
      employeeId: data?.id,
      institutionBranch: "string",
      institutionCode: getBankCodePaymentMethod(data?.institutionCode),
      institutionName: getBankNamePaymentMethod(data?.institutionCode),
      isDefault: data.default,
      paymentOption: "BANK_ACCOUNT",
    }));
    const finalData = {
      employerId: sessionStorage?.getItem("employer_id"),
      paymentMethods: datar,
      pin: employerPin,
    };
    axios
      .put(`${config.baseUrl}/v1/payment-methods/bulk`, finalData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadUpdateBulk(false);
        if (res.data.status) {
          for (var i = 0; i < res.data?.result?.length; i++) {
            if (
              finalData?.paymentMethods[i]?.employeeId ===
              res.data?.result[i]?.employeeId
            ) {
              const updatedDataEmployee = paymentMethodLists?.map((item) =>
                item.id === res.data.result[i]?.employeeId
                  ? {
                      ...item,
                      messages: res.data.result[i].messages,
                      status: res.data.result[i].status,
                    }
                  : item
              );
              setPaymentMethodLists(updatedDataEmployee);
            }
          }
          Notification("success", "Success", res.data.message);
          setEmployerPin("");
          setEnableAllPayment(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateBulk(false);
        if (err) {
        }
      });
  };

  console.log(paymentMethodLists);

  const handleSubmitPaymentMethodAdd = () => {
    setPinModal(false);
    const data = dataRow;
    const dataUpdate = paymentMethodLists.map((item) =>
      item.id === data?.id ? { ...item, loadSalaryUpdate: true } : item
    );
    setPaymentMethodLists(dataUpdate);
    axios
      .post(
        `${config.baseUrl}/v1/payment-methods`,
        {
          accountIdentifier: data?.accountNum,
          accountName: data?.accountName,
          countryCode: data?.country,
          currencyCode: data?.currencyCode,
          employeeId: data.id,
          employeeIds: [data.id],
          generateSalaryAccount: true,
          institutionBranch: "string",
          institutionCode: getBankCodePaymentMethod(data?.institutionCode),
          institutionName: getBankNamePaymentMethod(data?.institutionCode),
          isDefault: data.default,
          isEditable: true,
          paymentOption: "BANK_ACCOUNT",
          pin: employerPin,
          userId: "string",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const dataUpdate = paymentMethodLists.map((item) =>
          item.id === data?.id ? { ...item, loadSalaryUpdate: false } : item
        );
        setPaymentMethodLists(dataUpdate);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setEmployerPin("");
          getEmployeePaymentMethods();
          setEnableAllPayment(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        const dataUpdate = paymentMethodLists.map((item) =>
          item.id === data?.id ? { ...item, loadSalaryUpdate: false } : item
        );
        setPaymentMethodLists(dataUpdate);
        if (err) {
        }
      });
  };

  const handleSubmitPaymentMethodUpdate = () => {
    setPinModal(false);
    const data = dataRow;
    const dataUpdate = paymentMethodLists.map((item) =>
      item.id === data?.id ? { ...item, loadSalaryUpdate: true } : item
    );
    setPaymentMethodLists(dataUpdate);
    axios
      .put(
        `${config.baseUrl}/v1/payment-methods/${data?.paymentMethodId}`,
        {
          accountIdentifier: data?.accountNum,
          accountName: data?.accountName,
          countryCode: data?.country,
          currencyCode: data?.currencyCode,
          employeeId: data.id,
          employeeIds: [data.id],
          generateSalaryAccount: true,
          institutionBranch: "string",
          institutionCode: getBankCodePaymentMethod(data?.institutionCode),
          institutionName: getBankNamePaymentMethod(data?.institutionCode),
          isDefault: data.default,
          isEditable: true,
          paymentOption: "BANK_ACCOUNT",
          pin: employerPin,
          userId: "string",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const dataUpdate = paymentMethodLists.map((item) =>
          item.id === data?.id ? { ...item, loadSalaryUpdate: false } : item
        );
        setPaymentMethodLists(dataUpdate);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setEmployerPin("");
          getEmployeePaymentMethods();
          setEnableAllPayment(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        const dataUpdate = paymentMethodLists.map((item) =>
          item.id === data?.id ? { ...item, loadSalaryUpdate: false } : item
        );
        setPaymentMethodLists(dataUpdate);
        if (err) {
        }
      });
  };

  const handleSubmitSalaryUpdate = (data) => {
    const updatedDataAmount = salaryEmployeeDataUpdate.map((item) =>
      item.id === data?.id ? { ...item, loadSalaryUpdate: true } : item
    );
    setDataEmployeeSalaryUpdate(updatedDataAmount);
    axios
      .put(
        `${config.baseUrl}/v1/employees/salary-detail/${data?.id}`,
        {
          annualGrossSalary: data?.annualGrosserySalaryUpdate?.replace(
            /[₦,]/g,
            ""
          ),
          bonusAmount: data?.bonusAmount,
          computeNHF: false,
          computePension: data?.checkedValues?.includes("Pension"),
          computeTax: data?.checkedValues?.includes("Tax"),
          payGroupId: data?.paygroup,
          employeeId: data?.id,
          prorateType: data?.prorateType,
          prorateValue: data?.prorateType?.includes("AMOUNT")
            ? data?.prorateValue?.replace(/[₦,]/g, "")
            : data?.prorateValue,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setDataEmployeeSalaryUpdate(updatedDataAmount);

        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setEnableAll(false);
          getEmployeeSalaryUpdatesList();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        const updatedDataAmount = salaryEmployeeDataUpdate.map((item) =>
          item.id === data?.id ? { ...item, loadSalaryUpdate: false } : item
        );
        setDataEmployeeSalaryUpdate(updatedDataAmount);
        if (err) {
        }
      });
  };

  const confirmBusiness = (data) => {
    axios
      .post(`${config.baseUrl}/v1/kyc-details/lookUpKycDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setMsg(res.data.message);
          setKycId(res.data.result.id);
          setOpenLookUpKycModal(true);
          if (res.data.result.verifyBy === "NONE") {
            defaultVerify(
              res.data.result.id,
              businessIdentity,
              selectedBusinessdentityType
            );
          } else {
            setConfirmationResult(res.data.result);
          }
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNokPhone(truncatedValue);
          break;
        case "pfaPin":
          setPfaPin(truncatedValue);
          break;
        case "accNo":
          setAccountNum(truncatedValue);

          break;
        case "taxId":
          setTaxId(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (accountNum?.length === 10) {
      fetchAccName();
    }
  }, [accountNum]);

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const getBusinessKyc = (country) => {
    setCountryBusiness(country);
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/businessKycTypes?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setBusinessIdentityType(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPagedFilter = (queryString) => {
    axios
      .post(
        `${config.baseUrl}/v1/employees/filtered/paged${queryString}`,
        {
          department: departmentSelected,
          employeeType: employeeTypeSelected,
          employerId: sessionStorage.getItem("employer_id"),
          endDate: convertReadableDate(endDateFilter),
          level: employeeLevelSelected,
          position: positionSelected,
          startDate: convertReadableDate(startDateFilter),
          status: statusFilter,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          const employeDatas = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setEmployeeData(
            employeDatas.map((data) => ({
              employeeCode: data.employeeCode,
              firstName: data.firstName,
              lastName: data.lastName,
              otherName: data.otherName,
              user_id: data.userId,
              checked: false,
              created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
              mobile: data.mobile,
              paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
              workEmail: data.workEmail,
              id: data.id,
              salary: data.monthlyNetSalary
                ? data.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
                : "",
              status: data.status ? "ACTIVE" : "INACTIVE",
              email: data?.emailAddress,
            }))
          );
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPagedSalaryUpdateList = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/employees/paged${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const employeDatas = res.data.result;
          setDataEmployeeSalaryUpdate(
            employeDatas.map((data) => ({
              employeeCode: data.employeeCode,
              firstName: data.firstName,
              computeStarted: false,
              lastName: data.lastName,
              paygroup: data?.payGroup,
              otherName: data.otherName,
              loadSalaryUpdate: false,
              monthlyNetCompute: data?.monthlyNetSalary,
              checkedValues: [
                data?.computePension ? "Pension" : "",
                data?.computeTax ? "Tax" : "",
              ],
              user_id: data.userId,
              annualGrosserySalaryUpdate: formatNumber(data?.annualGrossSalary),
              employeeType: data?.employeeType,
              checked: false,
              prorateType: data?.proRateType,
              prorateValue: data?.proRateType?.includes("AMOUNT")
                ? formatNumber(data?.proRateValue)
                : data?.proRateValue,
              created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
              mobile: data.mobile,
              paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
              workEmail: data.workEmail,
              editedStatus: false,
              id: data.id,
              country: data?.country,
              currency: data?.salaryCurrency,
              salary: data.monthlyNetSalary
                ? data.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
                : "",
              status: data.status ? "ACTIVE" : "INACTIVE",
              personalEmail: data?.personalEmail,
            }))
          );
          setTotalItemSalaryUpdate(res.data.totalPages * 10);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/employees/paged${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const employeDatas = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setEmployeeData(
            employeDatas.map((data) => ({
              employeeCode: data.employeeCode,
              firstName: data.firstName,
              lastName: data.lastName,
              otherName: data.otherName,
              user_id: data.userId,
              checked: false,
              created_date: data.createdAt ? data?.createdAt?.slice(0, 10) : "",
              mobile: data.mobile,
              paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
              workEmail: data.workEmail,
              id: data.id,
              salary: data.monthlyNetSalary
                ? data.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.monthlyNetSalary)
                : "",
              status: data.status ? "ACTIVE" : "INACTIVE",
              email: data?.emailAddress,
            }))
          );
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const disabledDatehire = (current) => {
    return current && current < moment().endOf("day");
  };

  const getBankCodePaymentMethod = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.value) {
      return bank?.value;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.value;
    }
  };

  const getBankNamePaymentMethod = (id) => {
    var bank = bankLists?.find((x) => x.label === id);
    if (bank?.label) {
      return bank?.label;
    } else {
      var bankCheck = bankLists?.find((x) => x.value === id);
      return bankCheck?.label;
    }
  };

  const handleInputChange = (fieldName) => (e) => {
    const inputValue = e.target.value;
    const lettersOnly = inputValue.replace(/[^A-Za-z]/g, "");
    const lettersOnlyandSpace = inputValue.replace(/^(?! )[A-Za-z\s]*$/g, "");
    // const emailOnly = inputValue.replace(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/g, "");

    switch (fieldName) {
      case "firstName":
        setFirstName(lettersOnly);
        break;
      case "personalEmail":
        setPersonalEmail(inputValue);
        break;
      case "lastName":
        setLastName(lettersOnly);
        break;
      case "otherName":
        setOtherName(lettersOnly);
        break;
      case "nok":
        setNok(inputValue);
        break;

      default:
        break;
    }
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              {!loadEmployees && (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="d-flex align-items-top">
                        <div class="">
                          <h5 className="card-title">
                            Employees{" "}
                            <span className="border-countt">{totalData}</span>
                          </h5>
                        </div>
                        <div>
                          <Select
                            defaultValue="Status "
                            value={statusFilterBar}
                            style={{
                              width: 100,
                              marginTop: "-1.5rem",
                              marginLeft: "1rem",
                            }}
                            onChange={(val) => {
                              getEmployeeFilter(val);
                              setStatusFilterBar(val);
                            }}
                            options={[
                              {
                                value: "active",
                                label: "Active ",
                              },
                              {
                                value: "inactive",
                                label: "Inactive ",
                              },
                              {
                                value: "",
                                label: "All ",
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center">
                      <Dropdown
                        className=""
                        menu={{
                          items,
                        }}
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <button
                            className="btn btn-info m-b-xs  mb-4"
                            style={{
                              background: "#1FC157",
                              color: "white",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            Get Started <DownOutlined />
                          </button>{" "}
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="" style={{ position: "relative" }}>
                    <div className="filter-barz">
                      <div className="d-flex align-items-center">
                        <div className="">
                          {" "}
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{
                              border: "1px dotted #1FC157",
                              background: "white",
                              color: "1FC157",
                              height: "38px",
                              borderRadius: "7px",
                            }}
                            onClick={() => {
                              setOpenFilterModal(true);
                              setFilteringPagination(true);
                            }}
                          >
                            Filter
                          </button>
                        </div>
                        <div className="">
                          {" "}
                          <button
                            type="submit"
                            class="btn btn-primary ml-3"
                            style={{
                              border: "1px dotted #1FC157",
                              color: "1FC157",
                              background: "white",
                              height: "38px",
                              marginLeft: "1rem",
                              borderRadius: "7px",
                            }}
                            onClick={() => {
                              setOpenBulkSalaryUpdate(true);
                              setEnableAll(false);
                            }}
                          >
                            Manage Salary
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary ml-3"
                            style={{
                              border: "1px dotted #1FC157",
                              color: "1FC157",
                              background: "white",
                              height: "38px",
                              marginLeft: "1rem",
                              borderRadius: "7px",
                            }}
                            onClick={() => {
                              setOpenBulkSPaymentUpdate(true);
                              setEnableAllPaymentMethod(false);
                            }}
                          >
                            Manage Payment Method
                          </button>
                        </div>
                      </div>
                    </div>
                    <div style={{ position: "relative" }}>
                      <div className="cl-delete-trash">
                        <div className="d-flex align-items-center">
                          {/* <div className="d-flex align-items-center ">
                            <div
                              className="bord-delete-emp"
                              onClick={() => {
                                setOpenBulkSalaryUpdate(true)
                              }}
                            >
                              {" "}
                              <Tooltip title="Bulk Salary Update">
                                <i
                                  style={{ color: "#1fc157" }}
                                  className="fa fa-krw"
                                ></i>
                              </Tooltip>
                            </div>
                          </div> */}
                          {listChecks?.length > 0 && (
                            <>
                              <div className="d-flex align-items-center ">
                                <div
                                  className="bord-delete-emp"
                                  onClick={requestPaymentMethodBulk}
                                >
                                  {" "}
                                  <Tooltip title="Request payment method from multiple employee(s)">
                                    <i
                                      style={{ color: "#1fc157" }}
                                      className="fa fa-krw"
                                    ></i>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="d-flex align-items-center ">
                                <div
                                  className="bord-delete-emp"
                                  onClick={bulkInvite}
                                >
                                  {" "}
                                  <Tooltip title="Resend invite to employee(s">
                                    <i
                                      style={{ color: "#1fc157" }}
                                      className="fa fa-sitemap"
                                    ></i>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="d-flex align-items-center ">
                                <div
                                  className="bord-delete"
                                  onClick={onDeleteMultipleEmployees}
                                >
                                  {" "}
                                  <Tooltip title="Delete employee(s)">
                                    <i
                                      style={{ color: "#1fc157" }}
                                      className="fa fa-trash"
                                    ></i>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div style={{ position: "relative" }}>
                      <div class="form-check tb-check-head">
                        <input
                          class="form-check-input border-checks"
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </div>
                    </div>

                    <div style={{ overflowX: "auto" }} className="pb-4">
                      <ReactDatatable
                        config={configTable}
                        records={employeeData}
                        columns={columns}
                        show_pagination={false}
                        extraButtons={extraButtons}
                        onChange={customSort}
                      />
                    </div>
                    {employeeData?.length > 0 && (
                      <div className="text-center pagination-part pt-5">
                        <Pagination
                          current={page}
                          total={totalItems}
                          defaultPageSize={10}
                          onChange={pagination}
                          itemRender={itemRender}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {loadEmployees && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={addEmployee ? "Individual Onboarding" : "Bulk Onboarding"}
        visible={openEmployee}
        footer={false}
        width={addEmployee ? 1000 : 1500}
        onCancel={cancelAddEmployee}
        maskClosable={false}
        // maskStyle={{background: '#f3f4f7'}}
      >
        <form>
          {addEmployee ? (
            <div>
              <form>
                {personalInfo && (
                  <div>
                    <div className="font-weight-bold d-flex justify-content-center align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <span
                          type="submit"
                          className="font-weight-bold xx-btn"
                          style={{
                            cursor: "pointer",
                            marginRight: "1rem",
                          }}
                        >
                          <b>Step 1/3 </b>
                        </span>

                        <div className="">Personal Information</div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            First Name <span className="text-danger ">* </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={firstName}
                            id="exampleInputFirstName"
                            aria-describedby="firstNameHelp"
                            {...register("firstName", {
                              required: "First Name is required!",

                              maxLength: {
                                value: 30,
                                message: `First name cannnot be more than 30 characters`,
                              },
                            })}
                            onChange={handleInputChange("firstName")}
                            maxLength="30"
                          />
                          {errors.firstName && (
                            <span className="validate-error">
                              {errors.firstName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Last Name <span className="text-danger ">* </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={lastName}
                            id="exampleInputLastName"
                            maxLength="30"
                            aria-describedby="lastNameHelp"
                            {...register("lastName", {
                              required: "Last Name is required!",

                              maxLength: {
                                value: 30,
                                message: `Last name cannnot be more than 30 characters`,
                              },
                            })}
                            onChange={handleInputChange("lastName")}
                          />
                          {errors.lastName && (
                            <span className="validate-error">
                              {errors.lastName.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Other Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name="otherName"
                            value={otherName}
                            id="exampleInputEmail1"
                            maxLength="30"
                            aria-describedby="emailHelp"
                            onChange={handleInputChange("otherName")}
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Personal Email
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            name="workEmail"
                            value={personalEmail}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("personalEmail", {
                              required: false,
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/i,
                                message: "Invalid Email Format",
                              },
                              onChange: (e) => setPersonalEmail(e.target.value),
                              value: personalEmail,
                            })}
                          />
                          {errors.personalEmail && (
                            <span className="validate-error">
                              {errors.personalEmail.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Mobile Number{" "}
                            <span className="text-danger ">* </span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            value={mobile}
                            name="mobile"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("mobile", {
                              required: "Mobile Number is required!",

                              onChange: handleChangeNumber.bind(
                                this,
                                "mobile",
                                15
                              ),
                              value: mobile,
                            })}
                          />
                          {errors.mobile && (
                            <span className="validate-error">
                              {errors.mobile.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Gender <span className="text-danger ">* </span>
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="gender"
                            value={gender}
                            {...register("gender", {
                              required: "Gender is required!",
                              onChange: (e) => setGender(e.target.value),
                              value: gender,
                            })}
                          >
                            <option selected>Select</option>
                            {genderData.map((gender) => {
                              return (
                                <option value={gender.value}>
                                  {gender.value}
                                </option>
                              );
                            })}
                          </select>
                          {errors.gender && (
                            <span className="validate-error">
                              {errors.gender.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div>
                          <label for="exampleInputEmail1" class="form-label">
                            Date of Birth{" "}
                            <span className="text-danger ">* </span>
                          </label>

                          <Controller
                            name="dob"
                            control={control}
                            {...register("dob", {
                              required: "Date of birth is required!",
                            })}
                            render={({ field }) => (
                              <DatePicker
                                onChange={onChange}
                                style={{
                                  height: "3rem",
                                  width: "100%",
                                  borderRadius: "10px",
                                }}
                                value={dob}
                                disabledDate={(current) =>
                                  current.isAfter(moment())
                                }
                              />
                            )}
                          />
                          {errors.dob && (
                            <span className="validate-error">
                              {errors.dob.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="" class="">
                              Contact Address{" "}
                              <span className="text-danger ">* </span>
                            </label>
                            <div
                              style={{ color: "#1fc157", cursor: "pointer" }}
                              className="mt-2"
                              onClick={() => {
                                setMnaualAddress(!mmanualAddress);
                                setContactAddress("");
                                setValue("address", "");
                              }}
                            >
                              {mmanualAddress ? (
                                <div
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                    borderRadius: "7px",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Enter Address Automatically
                                </div>
                              ) : (
                                <div
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                    borderRadius: "7px",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Enter Address Manually
                                </div>
                              )}
                            </div>
                          </div>
                          {mmanualAddress ? (
                            <div>
                              <input
                                type="text"
                                class="form-control mt-2"
                                name="address mt-2"
                                id="exampleInputEmail1"
                                value={contactAddress}
                                aria-describedby="emailHelp"
                                {...register("address", {
                                  required: "Address is required!",
                                  onChange: (e) =>
                                    setContactAddress(e?.target?.value),
                                })}
                                maxLength="200"
                              />
                              {errors.address && (
                                <span className="validate-error">
                                  {errors.address.message}
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="pt-2">
                              <Location
                                setAddress={setContactAddress}
                                setCity={setCity}
                                setState={setState}
                                setLocationInfo={setLocationInfo}
                                register={register}
                                control={control}
                                setCity={setCity}
                                errors={errors}
                                watch={watch}
                                registerVal={"contactAddress"}
                                setValue={setValue}
                                setNokA={setNokA}
                                reset={reset}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Next of Kin Name{" "}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name="nok"
                            value={nok}
                            maxLength="60"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("nok", {
                              required: false,
                              onChange: handleInputChange("nok"),
                              maxLength: {
                                value: 60,
                                message: `Next of Kin name cannot be more than 60 characters`,
                              },
                              value: nok,
                            })}
                          />
                          {errors.nok && (
                            <span className="validate-error">
                              {errors.nok.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="" class="">
                              Next of Kin Address{" "}
                            </label>
                            <div
                              style={{ color: "#1fc157", cursor: "pointer" }}
                              onClick={() => {
                                setNokA("");
                                setMnaualAddressNok(!mmanualAddressNok);
                                setValue("addressKin", "");
                              }}
                            >
                              {mmanualAddressNok ? (
                                <div
                                  className=""
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                    borderRadius: "7px",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Enter Address Automatically
                                </div>
                              ) : (
                                <div
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                    borderRadius: "7px",
                                    padding: "2px 8px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Enter Address Manually
                                </div>
                              )}
                            </div>
                          </div>
                          {mmanualAddressNok ? (
                            <div>
                              <input
                                type="text"
                                class="form-control mt-2"
                                name="nokA"
                                value={nokA}
                                aria-describedby="emailHelp"
                                {...register("nokA", {
                                  required: false,
                                  onChange: (e) => setNokA(e?.target?.value),
                                })}
                                maxLength="200"
                              />
                              {errors.nokA && (
                                <span className="validate-error">
                                  {errors.nokA.message}
                                </span>
                              )}
                            </div>
                          ) : (
                            <div className="pt-2">
                              <Location2
                                setAddress={setNokA}
                                setCity={setCity}
                                setState={setState}
                                setLocationInfo={setLocationInfo}
                                register={register}
                                control={control}
                                errors={errors}
                                watch={watch}
                                reset={reset}
                                setValue={setValue}
                                registerVal={"nokA"}
                                setAddressContact={setContactAddress}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Next of Kin Phone Number{" "}
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            value={nokPhone}
                            name="nokPhone"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("nokPhone", {
                              required: false,

                              onChange: handleChangeNumber.bind(
                                this,
                                "nokPhone",
                                15
                              ),
                              value: nokPhone,
                            })}
                          />
                          {errors.nokPhone && (
                            <span className="validate-error">
                              {errors.nokPhone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Country <span className="text-danger ">* </span>
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="country"
                            // onChange={countryChange}
                            {...register("countryChoose", {
                              required: "Country is required!",
                              onChange: countryChange,
                              value: countryOnboard,
                            })}
                          >
                            <option selected>Select</option>
                            {countryOptions}
                          </select>
                          {errors.countryChoose && (
                            <span className="validate-error">
                              {errors.countryChoose.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Marital Status{" "}
                            <span className="text-danger ">* </span>
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="maritalStatus"
                            // onChange={(e) => setMaritalStatus(e.target.value)}
                            {...register("maritalStatus", {
                              required: "Marital Status is required!",
                              onChange: (e) => setMaritalStatus(e.target.value),
                              value: maritalStatus,
                            })}
                          >
                            <option selected>Select</option>
                            {maritalData.map((martial) => {
                              return (
                                <option value={martial.value}>
                                  {martial.value}
                                </option>
                              );
                            })}
                          </select>
                          {errors.maritalStatus && (
                            <span className="validate-error">
                              {errors.maritalStatus.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        onClick={handleSubmit(gotoWork)}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
                {workInfo && (
                  <div>
                    <div className="font-weight-bold d-flex justify-content-center align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <span
                          type="submit"
                          className="font-weight-bold xx-btn"
                          style={{
                            cursor: "pointer",
                            marginRight: "1rem",
                          }}
                        >
                          <b>Step 2/3 </b>
                        </span>

                        <div className="">Work Information</div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Work Email <span className="text-danger ">* </span>
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            name="workEmail"
                            value={workEmail}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("workEmail", {
                              required: "Work Email is required!",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/i,
                                message: "Invalid Email Format",
                              },
                              onChange: (e) => setWorkEmail(e.target.value),
                              value: workEmail,
                            })}
                          />
                          {errors.workEmail && (
                            <span className="validate-error">
                              {errors.workEmail.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="exampleInputEmail1" class="form-label">
                              Employee Type
                            </label>
                          </div>
                          <select
                            class="form-select"
                            name="employeeType"
                            aria-label="Default select example"
                            // onChange={(e) => setEmployeeType(e.target.value)}
                            {...register("employeeType", {
                              required: "Employee Type is required!",
                              onChange: (e) => {
                                setEmployeeType(e.target.value);
                                setPayGroup("");
                              },
                              value: employeeType,
                            })}
                            value={employeeType}
                          >
                            <option selected>Select</option>
                            {employeeTypeData.map((employeeType) => {
                              return (
                                <option selected>{employeeType.value}</option>
                              );
                            })}
                          </select>
                          {errors.employeeType && (
                            <span className="validate-error">
                              {errors.employeeType.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="exampleInputEmail1" class="form-label">
                              Position
                            </label>
                            <div>
                              <span
                                type="submit"
                                className="font-weight-bold xx-btn"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  setAddTypeName("Position");
                                  setVisible(true);
                                }}
                              >
                                <b> Manage Positions</b>
                              </span>
                            </div>
                          </div>
                          <select
                            class="form-select"
                            name="position"
                            aria-label="Default select example"
                            onChange={(e) => setPosition(e.target.value)}
                            value={position}
                          >
                            <option selected>Select</option>
                            {positionptions}
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="exampleInputEmail1" class="form-label">
                              Department
                            </label>
                            <div>
                              <span
                                type="submit"
                                className="font-weight-bold xx-btn"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  setAddTypeName("Department");
                                  setVisible(true);
                                }}
                              >
                                <b> Manage Departments</b>
                              </span>
                            </div>
                          </div>
                          <select
                            class="form-select"
                            name="department"
                            aria-label="Default select example"
                            onChange={(e) => setDepartment(e.target.value)}
                            value={department}
                          >
                            <option selected>Select</option>
                            {departmentOptions}
                          </select>
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="exampleInputEmail1" class="form-label">
                              Pay Level
                            </label>
                            <div>
                              <span
                                type="submit"
                                className="font-weight-bold xx-btn"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  history?.push("/manage_paylevel");
                                }}
                              >
                                <b> Manage Pay Level</b>
                              </span>
                            </div>
                          </div>
                          <select
                            class="form-select"
                            name="level"
                            aria-label="Default select example"
                            // onChange={(e) => setLevel(e.target.value)}
                            {...register("paylevel", {
                              required: "Pay level is required",
                              onChange: (e) => getPayScales(e?.target?.value),
                              value: payLevel,
                            })}
                            value={payLevel}
                          >
                            <option selected>Select</option>
                            {payLevelDatas.map((level) => {
                              return (
                                <option value={level.id}>{level.name}</option>
                              );
                            })}
                          </select>
                          {errors.paylevel && (
                            <span className="validate-error">
                              {errors.paylevel.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="exampleInputEmail1" class="form-label">
                              Pay Scale
                            </label>
                            <div>
                              <span
                                type="submit"
                                className="font-weight-bold xx-btn"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  history?.push("/manage_payscale");
                                }}
                              >
                                <b> Manage Pay Scale</b>
                              </span>
                            </div>
                          </div>
                          <select
                            class="form-select"
                            name="payScale"
                            aria-label="Default select example"
                            // onChange={(e) => setLevel(e.target.value)}
                            {...register("payScale", {
                              required: "Pay Scale is required",
                              onChange: (e) => setPayScale(e?.target?.value),
                              value: payScale,
                            })}
                            value={payScale}
                          >
                            <option selected>Select</option>
                            {payScaleData.map((scale) => {
                              return (
                                <option value={scale.id}>{scale.name}</option>
                              );
                            })}
                          </select>
                          {errors.payScale && (
                            <span className="validate-error">
                              {errors.payScale.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <label for="exampleInputEmail1" class="form-label">
                              Pay Group
                            </label>
                          </div>

                          <Select
                            mode="multiple"
                            style={{
                              width: "100%",
                              marginRight: "1rem",
                            }}
                            placeholder=""
                            onChange={(val) => {
                              setPayGroup(val);
                            }}
                            options={payGroupDataList}
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div>
                          <label for="exampleInputEmail1" class="form-label">
                            Hire Date <span className="text-danger ">* </span>
                          </label>{" "}
                          <Controller
                            name="hireDate"
                            control={control}
                            {...register("hireDate", {
                              required: "Hire Date is required!",
                            })}
                            render={({ field }) => (
                              <DatePicker
                                onChange={onChangeHireDate}
                                style={{ height: "3rem", width: "100%" }}
                                value={hireDate}
                                disabledDate={(current) =>
                                  current.isAfter(moment())
                                }
                              />
                            )}
                          />
                          {errors.hireDate && (
                            <span className="validate-error">
                              {errors.hireDate.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div class="col-sm-12 col-md-6 ">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Salary Currency{" "}
                            <span className="text-danger ">* </span>
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="salaryCurrency"
                            // onChange={(e) => setSalaryCurrency(e.target.value)}
                            {...register("salaryCurrency", {
                              required: "Salary Currency is required!",
                              onChange: (e) =>
                                setSalaryCurrency(e.target.value),
                              value: salaryCurrency,
                            })}
                          >
                            <option selected>Select</option>
                            {currencyOptions}
                          </select>
                          {errors.salaryCurrency && (
                            <span className="validate-error">
                              {errors.salaryCurrency.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {salaryCurrency &&
                        hireDate &&
                        employeeType &&
                        workEmail && (
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Annual Gross Salary{" "}
                                <span className="text-danger ">* </span>
                              </label>
                              <Controller
                                name="annualGrossery"
                                control={control}
                                {...register("annualGrossery", {
                                  required: "Annual Gross Salary is required!",
                                  onChange: (e) =>
                                    setAnnualGrossery(e.target.value),
                                  value: annualGrossery,
                                })}
                                render={({ field }) => (
                                  <NumberFormatter
                                    {...field}
                                    label="Annual Gross Salary"
                                    name="amount"
                                    error={errors.annualGrossery?.message}
                                    prefix={"₦"}
                                  />
                                )}
                              />
                              <div>
                                {showNetCompute && (
                                  <div>
                                    {monthlyNetSalaryVal !== 0 ? (
                                      <div
                                        className="text-right d-flex justify-content-end "
                                        style={{
                                          color: "#1fc157",
                                          textAlign: "right",
                                        }}
                                      >
                                        Monthly Net Salary- {salaryCurrency}{" "}
                                        {Intl.NumberFormat("en-US").format(
                                          monthlyNetSalaryVal
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="text-right d-flex justify-content-end "
                                        style={{
                                          color: "#1fc157",
                                          textAlign: "right",
                                        }}
                                      >
                                        Monthly Net Salary- 0
                                      </div>
                                    )}
                                  </div>
                                )}

                                {errors.annualGrossery && (
                                  <span className="validate-error">
                                    {errors.annualGrossery.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>

                    <div className="mt-3 d-flex justify-content-between align-items-center">
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        onClick={gotoPersonal}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        Previous
                      </button>
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        onClick={handleSubmit(gotoPayment)}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {paymentInfo && (
                  <div>
                    <div className="font-weight-bold d-flex justify-content-center align-items-center pb-3">
                      <div className="d-flex align-items-center">
                        <span
                          type="submit"
                          className="font-weight-bold xx-btn"
                          style={{
                            cursor: "pointer",
                            marginRight: "1rem",
                          }}
                        >
                          <b>Step 3/3 </b>
                        </span>

                        <div className="">Payment Information</div>
                      </div>
                    </div>

                    <div className="row">
                      <form>
                        {mobileMoneyErr && (
                          <div className="pb-3">
                            <Alert
                              message="Country, Mobile money type, and Mobile Number are required!"
                              type="error"
                              showIcon
                            />
                          </div>
                        )}

                        <div class="mb-3">
                          <label for="floatingInput" className="form-label">
                            Payment Method
                          </label>
                          <select
                            class="form-select"
                            name="paymentOptions"
                            aria-label="Default select example"
                            onChange={setPaymentFunc}
                            // style={{ height: '3.6rem' }}
                          >
                            <option selected>Select </option>
                            {paymentOptions}
                          </select>
                        </div>
                      </form>
                    </div>
                    {bankSwitch && (
                      <div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label for="floatingInput" className="form-label">
                                Country
                              </label>
                              <select
                                class="form-select"
                                name="country"
                                aria-label="Default select example"
                                {...register("country", {
                                  required: "Country is required",
                                  onChange: countryChange,
                                  value: country,
                                })}
                              >
                                <option selected>Select</option>
                                {countryOptions}
                              </select>
                              {errors.country && (
                                <span className="validate-error">
                                  {errors.country.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label for="floatingInput" className="form-label">
                                Currency
                              </label>
                              <select
                                class="form-select"
                                name="currencyCode"
                                aria-label="Default sselect example"
                                {...register("currencyCode", {
                                  required: "Currency is required",
                                  onChange: (e) =>
                                    setCurrencyCode(e.target.value),
                                  value: currencyCode,
                                })}
                              >
                                <option selected value="">
                                  Select{" "}
                                </option>
                                {currencyOptions}
                              </select>
                              {errors.currencyCode && (
                                <span className="validate-error">
                                  {errors.currencyCode.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div class="col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label
                                  for="floatingInput"
                                  className="form-label"
                                >
                                  Bank
                                </label>
                                <Controller
                                  name="bankCode"
                                  control={control}
                                  {...register("bankCode", {
                                    required: "Bank name is required",
                                  })}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      value={bankCode}
                                      style={{
                                        width: "100%",
                                        height: "70px !important",
                                        borderRadius: "10px !important",
                                        marginBottom: ".8rem",
                                      }}
                                      onChange={(val) => {
                                        setBankCode(val);
                                        setValue("bankCode", val);
                                      }}
                                      options={bankLists}
                                      showSearch
                                      placeholder="Select a Bank"
                                      optionFilterProp="children"
                                      onSearch={onSearch}
                                      filterOption={(input, option) =>
                                        (option?.label ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                    />
                                  )}
                                />

                                <br />
                                {errors.bankCode && (
                                  <span className="validate-error mt-3">
                                    {errors.bankCode.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                              <div className="mb-3">
                                <label
                                  for="floatingInput"
                                  className="form-label"
                                >
                                  Account Number
                                </label>
                                <input
                                  type="text"
                                  name="accountNum"
                                  className="form-control"
                                  // onChange={(e) => setAccountNum(e.target.value)}
                                  {...register("accountNum", {
                                    required: "Account number is required",
                                    maxLength: {
                                      value: 10,
                                      message:
                                        "Account Number must be 10 digits",
                                    },
                                    minLength: {
                                      value: 10,
                                      message:
                                        "Account Number must be 10 digits",
                                    },
                                    onChange: handleChangeNumber.bind(
                                      this,
                                      "accNo",
                                      10
                                    ),

                                    value: accountNum,
                                  })}
                                />
                                {errors.accountNum && (
                                  <span className="validate-error">
                                    {errors.accountNum.message}
                                  </span>
                                )}
                                {loadBank && (
                                  <ThreeDots
                                    visible={loadBank}
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                )}
                                {accountName && !loadBank && (
                                  <span
                                    className="font-weight-bold"
                                    style={{ color: "#1fc157" }}
                                  >
                                    {accountName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {mobileMoneySwitch && (
                      <div>
                        <div className="row"></div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label for="floatingInput" className="form-label">
                                Mobile Network
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="bankCode"
                                // onChange={(e) => setBankCode(e.target.value)}
                                {...register("bankCode", {
                                  required: "Bank Name is required!",
                                  onChange: (e) => setBankCode(e.target.value),
                                  value: bankCode,
                                })}
                                value={bankCode}
                              >
                                <option selected>Select </option>
                                {moneyOptions}
                              </select>
                              {errors.bankCode && (
                                <span className="validate-error">
                                  {errors.bankCode.message}
                                </span>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label for="floatingInput" className="form-label">
                                Mobile Number
                              </label>
                              <input
                                type="text"
                                name="accountNum"
                                className="form-control"
                                value={accountNum}
                                // onChange={(e) => setAccountNum(e.target.value)}
                                {...register("accountNum", {
                                  required: "Account Number is required!",

                                  onChange: handleChangeNumber.bind(
                                    this,
                                    "accountNo",
                                    10
                                  ),
                                  value: accountNum,
                                })}
                              />
                              {errors.accountNum && (
                                <span className="validate-error">
                                  {errors.accountNum.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            PFA
                          </label>

                          <Select
                            showSearch
                            value={pfa}
                            style={{
                              width: "100%",
                              borderRadius: "10px !important",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(val) => {
                              if (val !== "Select") {
                                setPFA(val);
                                setPfaPin("");
                              } else {
                                setPFA("");
                                setPfaPin("");
                              }
                            }}
                            options={pfaOptionsLists}
                          />
                          {errors.pfa && (
                            <span className="validate-error">
                              {errors.pfa.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            PFA RSA Pin
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            name="pfaPin"
                            value={pfaPin}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("pfaPin", {
                              required: pfa
                                ? "PFA RSA Pin is required!"
                                : false,
                              onChange: handleChangeNumber.bind(
                                this,
                                "pfaPin",
                                15
                              ),
                              value: pfaPin,
                            })}
                          />
                          {errors.pfaPin && (
                            <span className="validate-error">
                              {errors.pfaPin.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Tax State
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            name="taxState"
                            // onChange={(e) => setTaxState(e.target.value)}
                            {...register("taxState", {
                              required: false,
                              onChange: (e) => {
                                if (e.target?.value !== "Select") {
                                  setTaxState(e.target.value);
                                  setTaxId("");
                                } else {
                                  setTaxState("");
                                  setTaxId("");
                                }
                              },
                              value: taxState,
                            })}
                            value={taxState}
                          >
                            <option selected value={"Select"}>
                              Select
                            </option>
                            {taxStateData.map((taxState) => {
                              return <option>{taxState.value}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Tax ID
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={taxid}
                            name="taxid"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            {...register("taxid", {
                              required: taxState
                                ? "Tax ID is required!"
                                : false,
                              onChange: handleChangeNumber.bind(
                                this,
                                "taxId",
                                10
                              ),

                              value: taxid,
                            })}
                          />
                          {errors.taxid && (
                            <span className="validate-error">
                              {errors.taxid.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        onClick={backtoWork}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        Previous
                      </button>
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        onClick={handleSubmit(addEmployeeFunc)}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        {load ? (
                          <ThreeDots
                            visible={load}
                            height="20"
                            width="50"
                            color="#ffffff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          ) : (
            <div style={{ padding: "0px 30px" }}>
              {loadExcel && (
                <div className="text-center">
                  <Spin />
                </div>
              )}
              {downloadTemp && (
                <div className="text-center">
                  <Spin />
                  <div>Downloading template</div>
                </div>
              )}

              <div className="d-flex justify-content-between align-items-center">
                <div className="text-">
                  <Alert
                    message="Click the Download Template button to download sample template for onboarding multiple employees in your company."
                    type="success"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  onClick={downloadBulkTemplate}
                  style={{ background: "#1FC157", color: "white" }}
                >
                  Download Template
                </button>
              </div>
              <br />

              <Dropzone onDrop={onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      {selectedFiles && selectedFiles.name ? (
                        <div className="selected-file">
                          {selectedFiles && selectedFiles.name}
                        </div>
                      ) : (
                        "Drag and drop file here, or click to select file"
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
              <br />
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  disabled={!selectedFiles}
                  className="btn btn-info m-b-xs"
                  onClick={handleSubmitExcel}
                  style={{ background: "#1FC157", color: "white" }}
                >
                  Upload
                </button>
              </div>
            </div>
          )}
        </form>
      </Modal>

      <Modal
        title={"Upload Result"}
        visible={showUploadRes}
        footer={false}
        width={1000}
        onCancel={cancelUploadRes}
        maskClosable={false}
      >
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <Tabs
                defaultActiveKey={activeTab}
                onChange={onChangeTab}
                items={tabSwicth}
                inkBarColor="#1fc157"
                itemActiveColor="#1fc157"
                itemHoverColor="#1fc157"
                itemSelectedColor="#1fc157"
              />
              {activeTab === "1" && (
                <div class="table-responsive">
                  {successUpload?.length > 0 ? (
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Work Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {successUpload?.map((data) => {
                          return (
                            <tr>
                              <td>{data.employeeCode}</td>
                              <td>{data.firstName}</td>
                              <td>{data.lastName}</td>
                              <td>{data.workEmail}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center pt-5">No successful upload</div>
                  )}
                </div>
              )}
              {activeTab === "2" && (
                <div class="table-responsive">
                  {validUpload?.length > 0 ? (
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Work Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {validUpload?.map((data) => {
                          return (
                            <tr>
                              <td>{data.employeeCode}</td>
                              <td>{data.firstName}</td>
                              <td>{data.lastName}</td>
                              <td>{data.workEmail}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center pt-5">No Valid upload</div>
                  )}
                </div>
              )}
              {activeTab === "3" && (
                <div class="table-responsive">
                  {failedUpload?.length > 0 ? (
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Employee Code</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Work Email</th>
                          <th scope="col">Error</th>
                        </tr>
                      </thead>
                      <tbody>
                        {failedUpload?.map((data) => {
                          return (
                            <tr>
                              <td>{data.employeeCode}</td>
                              <td>{data.firstName}</td>
                              <td>{data.lastName}</td>
                              <td>{data.workEmail}</td>
                              <td>
                                <button
                                  onClick={viewErrorMsg.bind(this, data)}
                                  style={{
                                    background: "red",
                                    color: "white",
                                    border: "1px solid red",
                                  }}
                                  size="small"
                                >
                                  View Error
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center pt-5">No Failed upload</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={"Filter Records"}
        visible={openFilterModal}
        footer={false}
        width={500}
        onCancel={() => {
          setOpenFilterModal(false);
          setStatusFilter("");
          setDepartmentSelected("");
          setPositionsSelected("");
          setEmployeeLevelSelected("");
          setEmployeeTypeSelected("");
          setStartDateFilter("");
          setEndDateFilter("");
        }}
        maskClosable={false}
      >
        {loadFilter && (
          <div className="text-center">
            <Spin />{" "}
          </div>
        )}
        <div className="">
          <div>
            <div>
              <div className="mb-3">
                <label>Start Date</label>

                <DatePicker
                  onChange={onChangeStartDate}
                  value={startDateFilter}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="Start Date"
                />
              </div>
              <div className="mb-3">
                <label>End Date</label>
                <DatePicker
                  onChange={onChangeEndDate}
                  value={endDateFilter}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    borderRadius: "10px",
                  }}
                  placeholder="End Date"
                />
              </div>
              <div className="mb-3">
                <label>Employee Level</label>
                <Select
                  mode="multiple"
                  value={employeeLevelSelected}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                    height: "3.3rem",
                  }}
                  onChange={(val) => {
                    setEmployeeLevelSelected(val);
                  }}
                  options={levelOptions}
                />
              </div>
              <div className="mb-3">
                <label>Department</label>
                <Select
                  mode="multiple"
                  value={departmentSelected}
                  style={{
                    width: "100%",
                    marginRight: "1rem",
                    height: "3.3rem",
                  }}
                  onChange={(val) => {
                    setDepartmentSelected(val);
                  }}
                  options={departmentLists}
                />
              </div>
              <div className="mb-3">
                <label>Position</label>
                <Select
                  mode="multiple"
                  value={positionSelected}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setPositionsSelected(val);
                  }}
                  options={positionOptions}
                />
              </div>
              <div className="mb-3">
                <label>Employee Type</label>
                <Select
                  mode="multiple"
                  value={employeeTypeSelected}
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setEmployeeTypeSelected(val);
                  }}
                  options={employeeTypeData?.map((d) => ({
                    value: d.value,
                    label: d.value,
                  }))}
                />
              </div>
              <div className="mb-3">
                <label>Status</label>
                <Select
                  mode="single"
                  style={{
                    width: "100%",
                    height: "3.3rem",
                    marginRight: "1rem",
                  }}
                  onChange={(val) => {
                    setStatusFilter(val);
                  }}
                  options={[
                    { value: "", label: "All Employees" },
                    { value: "inactive", label: "Inactive Employees" },
                    { value: "active", label: "Active Employees" },
                  ]}
                />
              </div>
              <br />
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={onFilerSubmit}
                  style={{ background: "#1FC157", color: "white" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={"KYC Details"}
        visible={kycModal}
        footer={false}
        width={1000}
        onCancel={cancelSetKyc}
        maskClosable={false}
      >
        {load && (
          <div className="text-center">
            <Spin />{" "}
          </div>
        )}
        <br />
        {steps === "personal" && (
          <div>
            <div className="font-weight-bold text-center pb-3">
              Personal Details
            </div>
            <br />
            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Country
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => getPersonalKyc(e.target.value)}
                    value={country}
                  >
                    <option selected>Select</option>
                    {contactDetails.countries ? (
                      contactDetails.countries.map((country) => {
                        return (
                          <option value={country.code}>{country.name}</option>
                        );
                      })
                    ) : (
                      <option></option>
                    )}
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Identity Type
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={setPersonalKycIdentityType}
                    value={selectedIdentityType}
                  >
                    <option selected>Select</option>
                    {identityType ? (
                      identityType.map((identity) => {
                        return (
                          <option value={identity.type}>{identity.name}</option>
                        );
                      })
                    ) : (
                      <option></option>
                    )}
                  </select>
                </div>
              </div>
              {identityDetails && (
                <div class="col-sm-12 col-md-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      {identityDetails}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={personalIdentity}
                      onChange={(e) => setPersonalIdentity(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={kycfirstName}
                    onChange={(e) =>
                      setKycFirstName(e.target.value.replace(/[^a-zA-Z]/g, ""))
                    }
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    disabled={contactDetails.firstName ? true : false}
                    maxLength="64"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    disabled={contactDetails.lastName ? true : false}
                    value={kyclastName}
                    onChange={(e) =>
                      setKycLastName(e.target.value.replace(/[^a-zA-Z]/g, ""))
                    }
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    maxLength="64"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Other Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    disabled={contactDetails.middleName ? true : false}
                    value={
                      contactDetails ? contactDetails.middleName : kycotherName
                    }
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) =>
                      setKycOthertName(e.target.value.replace(/[^a-zA-Z]/g, ""))
                    }
                    maxLength="64"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Date of Birth
                  </label>
                  {contactDetails.dateOfBirth ? (
                    <input
                      type="text"
                      class="form-control"
                      value={
                        contactDetails ? contactDetails.dateOfBirth : kycdob
                      }
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled
                      onChange={(e) => setKycDob(e.target.value)}
                    />
                  ) : (
                    <DatePicker
                      onChange={onChange}
                      style={{
                        height: "3rem",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Title
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setKycTitle(e.target.value)}
                    disabled={contactDetails.title ? true : false}
                    value={contactDetails ? contactDetails.title : kyctitle}
                  >
                    <option selected>Select</option>
                    <option>Dr</option>
                    <option>Miss</option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Ms</option>
                    <option>Prof</option>
                    <option>Rev</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Position
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setKycPosition(e.target.value)}
                    value={
                      contactDetails ? contactDetails.position : kycposition
                    }
                    disabled={contactDetails.position ? true : false}
                  >
                    <option selected>Select</option>
                    <option>Bursar</option>
                    <option>Chief Administrative Officer</option>
                    <option>Chief Executive Officer</option>
                    <option>Chief Financial Officer</option>
                    <option>Chief Information Officer</option>
                    <option>Chief Operating Officer</option>
                    <option> Chief Risk Officer</option>
                    <option> Chief Technology Officer</option>
                    <option> Chief of Staff Director</option>
                    <option> General Manager</option>
                    <option> Managing Director</option>
                    <option> President</option>
                    <option> Registrar</option>
                    <option> Secretary</option>
                    <option> Vice Chancellor</option>
                    <option> Vice President</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    disabled={contactDetails.phoneNumber ? true : false}
                    value={
                      contactDetails
                        ? contactDetails.phoneNumber
                        : kycphoneNumber
                    }
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => setKycPhoneNumber(e.target.value)}
                    maxLength="11"
                  />
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Gender
                  </label>
                  {contactDetails.gender ? (
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        disabled={contactDetails.gender ? true : false}
                        value={
                          contactDetails ? contactDetails.gender : kycgender
                        }
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setKycGender(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setKycGender(e.target.value)}
                        value={kycgender}
                      >
                        <option selected>Select</option>
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Marital Status
                  </label>
                  {contactDetails.maritalStatus ? (
                    <div>
                      <input
                        type="text"
                        class="form-control"
                        disabled={contactDetails.maritalStatus ? true : false}
                        value={
                          contactDetails
                            ? contactDetails.maritalStatus
                            : kycmaritalStatus
                        }
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setKycMaritalStatus(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setKycMaritalStatus(e.target.value)}
                        value={kycmaritalStatus}
                      >
                        <option selected>Select</option>
                        <option>Single</option>
                        <option>Married</option>
                        <option>Divorced</option>
                      </select>
                    </div>
                  )}
                </div>
              </div>
              {selectedIdentityType === "VIN" && (
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      State of PVC Enrollment
                    </label>

                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setPVCState(e.target.value)}
                        value={pvcState}
                      >
                        <option selected>Select</option>
                        {config.stateLists.map((state) => {
                          return <option>{state}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={getConfirmation.bind(this, "personal")}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
        {steps === "business" && (
          <div>
            <div className="font-weight-bold text-center pb-3">
              Business Details
            </div>
            <br />
            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Country
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => getBusinessKyc(e.target.value)}
                    value={countryBusiness}
                  >
                    <option selected>Select</option>
                    {contactDetails.countries ? (
                      contactDetails.countries.map((country) => {
                        return (
                          <option value={country.code}>{country.name}</option>
                        );
                      })
                    ) : (
                      <option></option>
                    )}
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Identity Type
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={setBusinessKycIdentityType}
                    value={selectedBusinessdentityType}
                  >
                    <option selected>Select</option>
                    {businessIdentityType ? (
                      businessIdentityType.map((identity) => {
                        return (
                          <option value={identity.type}>{identity.name}</option>
                        );
                      })
                    ) : (
                      <option></option>
                    )}
                  </select>
                </div>
              </div>

              {businessIdentityDetails && (
                <div class="col-sm-12 col-md-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      {businessIdentityDetails}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={businessIdentity}
                      onChange={(e) => setBusinessIdentity(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div class="col-sm-12 col-md-12">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Business Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={businessName}
                    disabled={contactDetails.businessName ? true : false}
                    id="exampleInputEmail1"
                    onChange={(e) => setBusinessName(e.target.value)}
                    aria-describedby="emailHelp"
                  />
                </div>
              </div>
            </div>
            {contactDetails.businessAddress &&
              contactDetails.dateOfRegistration && (
                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Business Address
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={kycaddress}
                        disabled={contactDetails.businessAddress ? true : false}
                        onChange={(e) => setKycAddress(e.target.value)}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Date of Registration
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        disabled={
                          contactDetails.dateOfRegistration ? true : false
                        }
                        value={
                          contactDetails
                            ? contactDetails.dateOfRegistration
                            : ""
                        }
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>
              )}
            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Industry
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    disabled={contactDetails.industry ? true : false}
                    onChange={(e) => setIndustry(e.target.value)}
                    value={industry}
                  >
                    <option selected>Select</option>
                    <option>Agriculture</option>
                    <option>Commerce</option>
                    <option>Education</option>
                    <option> Financial Services</option>
                    <option>Gaming</option>
                    <option>Hospitality</option>
                    <option> Health </option>
                    <option> Leisure & Entertainment</option>
                    <option> Logistics</option>
                    <option> Non-profits</option>
                    <option> Travel</option>
                    <option> Utilities</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Size
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSize(e.target.value)}
                    disabled={contactDetails.size ? true : false}
                    value={size}
                  >
                    <option selected>Select</option>
                    <option>1 - 30 employees</option>
                    <option>31 - 100 employee</option>
                    <option>101 - 300 employee</option>
                    <option> 300 - 1000 employee</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div>
                  <label for="exampleInputEmail1" class="form-label">
                    Website<span className="text-danger ">* </span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    disabled={contactDetails.website ? true : false}
                    value={website}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => setWebsite(e.target.value)}
                    maxLength="64"
                  />
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Support Email
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    disabled={contactDetails.supportEmail ? true : false}
                    value={supportEmail}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    onChange={(e) => setSupportEmail(e.target.value)}
                    maxLength="64"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Register Type
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    disabled={contactDetails.registerType ? true : false}
                    onChange={(e) => setRegisterType(e.target.value)}
                    value={registerType}
                  >
                    <option selected>Select</option>
                    <option>Incorporated Trustees</option>
                    <option>Incorporated Company</option>
                    <option>Business Name Registration</option>
                    <option> Free Zone Entity</option>
                    <option> Government Entity</option>
                    <option> Private Entity</option>
                    <option> Cooperative Society</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Ownership
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setOwnership(e.target.value)}
                    disabled={contactDetails.ownership ? true : false}
                    value={ownership}
                  >
                    <option selected>Select</option>
                    <option>Privately Held(2017 - 2020)</option>
                    <option>Public (2017 - 2020)</option>
                    <option> Privately Held(December 2020 to date)</option>
                    <option> Public (December 2020 to date)</option>
                    <option> Privately Held (Pre 2017)</option>
                    <option> Public (Pre 2017)</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">
                    Fiscal Month
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    value={fiscalMonth}
                    onChange={(e) => setFiscalMonth(e.target.value)}
                  >
                    <option selected>Select Month</option>
                    {config.fiscalMonths.map((month) => {
                      return <option>{month}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={backToPersonalKyc}
                >
                  Previous
                </button>
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={getConfirmation.bind(this, "business")}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>

      <Drawer
        title="Actions"
        placement="right"
        open={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          onClick={() => {
            history.push("/view_employee");
          }}
          style={{ color: "#000", cursor: "pointer" }}
        >
          Manage Employee Details
        </div>
        <hr />
        <div
          onClick={() => {
            history.push("/employee_salary_detail");
          }}
          style={{ color: "#000", cursor: "pointer" }}
        >
          Manage Salary Details
        </div>
        <hr />
        <div
          onClick={() => {
            history.push("/employee_payment-method");
          }}
          style={{ color: "#000", cursor: "pointer" }}
        >
          Manage Payment Method
        </div>
        <hr />
        <div
          onClick={() => {
            history.push("/employee_payroll-detail");
          }}
          style={{ color: "#000", cursor: "pointer" }}
        >
          Manage Payroll Detail
        </div>
        <hr />

        {userRole === "EMPLOYER" && (
          <>
            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={deleteEmployee}
            >
              Delete Employee
            </div>
            <hr />
            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={resendInvite}
            >
              Resend Invite
            </div>
            <hr />
            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={requestPaymentMethod}
            >
              Request Payment Method
            </div>

            <hr />

            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={changeStatus}
            >
              {employeeStatus === "ACTIVE" ? "Disable" : "Enable"}
            </div>
            <hr />
          </>
        )}

        {userRole === "HUMAN_RESOURCE" && (
          <>
            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={deleteEmployee}
            >
              Delete Employee
            </div>
            <hr />
            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={resendInvite}
            >
              Resend Invite
            </div>
            <hr />
            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={requestPaymentMethod}
            >
              Request Payment Method
            </div>

            <hr />

            <div
              style={{ color: "#000", cursor: "pointer" }}
              onClick={changeStatus}
            >
              {employeeStatus === "ACTIVE" ? "Disable" : "Enable"}
            </div>
            <hr />
          </>
        )}
      </Drawer>

      <Modal
        title="Manage Salary Details"
        visible={openBulkSalaryUpdate}
        okText="Skip"
        maskClosable={false}
        width={"100%"}
        onCancel={() => {
          setOpenBulkSalaryUpdate(false);
          setEnableAll(false);
        }}
        footer={false}
      >
        <div>
          <div className="d-flex justify-content-betweeen align-items-center">
            <div>
              <Select
                defaultValue="Status "
                value={statusFilterBar}
                style={{
                  width: 100,
                  marginTop: "-1.5rem",
                  marginLeft: "1rem",
                }}
                onChange={(val) => {
                  getEmployeeFilter(val);
                  setStatusFilterBar(val);
                }}
                options={[
                  {
                    value: "active",
                    label: "Active ",
                  },
                  {
                    value: "inactive",
                    label: "Inactive ",
                  },
                  {
                    value: "",
                    label: "All ",
                  },
                ]}
              />
            </div>
            <Button
              onClick={handleSubmitSalaryUpdateBulk}
              className="mr-5"
              style={{
                background: enableAll ? "#1FC157" : "grey",
                color: enableAll ? "white" : "silver",
              }}
            >
              {uploadBulkLoad ? "Saving" : "Save All"}
            </Button>
          </div>
          <br />
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Pay Group</th>
                  <th scope="col">Annual Gross Salary</th>
                  <th scope="col"> Monthtly Net Salary</th>
                  <th scope="col">Statutory Deduction</th>
                  <th scope="col">Prorate Type</th>
                  <th scope="col">Prorate Value </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {!loadEmployees && (
                <tbody>
                  {salaryEmployeeDataUpdate?.length > 0 && (
                    <>
                      {salaryEmployeeDataUpdate?.map((data, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="text-center">
                                {data?.firstName + " " + data?.lastName}
                              </div>
                              <div className="text-center">
                                ({data?.employeeCode})
                              </div>
                            </td>
                            <td>
                              <div class="">
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    if (e?.target?.value) {
                                      const updatedDataAmount =
                                        salaryEmployeeDataUpdate.map((item) =>
                                          item.id === data?.id
                                            ? {
                                                ...item,
                                                paygroup:
                                                  e?.target?.value === "Select"
                                                    ? ""
                                                    : e.target?.value,
                                                editedStatus: true,
                                              }
                                            : item
                                        );
                                      setEnableAll(true);
                                      setDataEmployeeSalaryUpdate(
                                        updatedDataAmount
                                      );
                                    }
                                  }}
                                  value={data?.paygroup}
                                >
                                  <option>Select</option>
                                  {payGroupDataList.map((paygroup) => {
                                    return (
                                      <option value={paygroup.id}>
                                        {paygroup.groupName
                                          ? paygroup.groupName.replace(
                                              /_+/g,
                                              " "
                                            )
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div class="">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    handleInputChangeSalaryUpdate(
                                      data,
                                      e.target.value
                                    )
                                  }
                                  class="form-control"
                                  aria-describedby="emaildHelp"
                                  value={data?.annualGrosserySalaryUpdate}
                                />
                              </div>
                            </td>
                            <td>
                              {data?.computeStarted ? (
                                <div>Computing...</div>
                              ) : (
                                <div>
                                  {data?.monthlyNetCompute && (
                                    <div className="">
                                      {data?.currency ? data?.currency : "₦"}{" "}
                                      {data?.monthlyNetCompute
                                        ? Intl.NumberFormat("en-US").format(
                                            data?.monthlyNetCompute
                                          )
                                        : 0}
                                    </div>
                                  )}
                                </div>
                              )}
                            </td>
                            <td>
                              <div class="mb-3">
                                <div className="pt-2">
                                  <Checkbox.Group
                                    options={checkOptions}
                                    defaultValue={data?.checkedValues}
                                    onChange={onChangeCheckboxManageSalary?.bind(
                                      this,
                                      data
                                    )}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div class="">
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    if (e?.target?.value) {
                                      const updatedDataAmount =
                                        salaryEmployeeDataUpdate.map((item) =>
                                          item.id === data?.id
                                            ? {
                                                ...item,
                                                prorateType: e.target?.value,
                                                editedStatus: true,
                                                prorateValue: "",
                                              }
                                            : item
                                        );
                                      if (e.target.value) {
                                        setEnableAll(true);
                                        setDataEmployeeSalaryUpdate(
                                          updatedDataAmount
                                        );
                                      }
                                    }
                                  }}
                                  value={data?.prorateType}
                                >
                                  {prorateDataType.map((type) => {
                                    return (
                                      <option value={type.code}>
                                        {type.value
                                          ? type.value.replace(/_+/g, " ")
                                          : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </td>
                            <td>
                              <div class="">
                                {data?.prorateType === "NONE" && (
                                  <div class="">
                                    <div class="">
                                      <div>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          value="NOT APPLICABLE"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {data?.prorateType === 0 && (
                                  <div class="">
                                    <div class="">
                                      <div>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          value="NOT APPLICABLE"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {data?.prorateType !== "NONE" && (
                                  <div class="">
                                    {data?.prorateType?.includes("AMOUNT") ? (
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          if (e.target?.value) {
                                            const updatedDataAmount =
                                              salaryEmployeeDataUpdate.map(
                                                (item) =>
                                                  item.id === data?.id
                                                    ? {
                                                        ...item,
                                                        prorateValue:
                                                          "₦" +
                                                          e?.target?.value
                                                            ?.replace(/\D/g, "")
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            ),
                                                        editedStatus: true,
                                                      }
                                                    : item
                                              );
                                            setEnableAll(true);
                                            setDataEmployeeSalaryUpdate(
                                              updatedDataAmount
                                            );
                                          }
                                        }}
                                        class="form-control"
                                        aria-describedby="emailHelp"
                                        value={data?.prorateValue}
                                      />
                                    ) : (
                                      <div>
                                        <input
                                          type="number"
                                          onChange={(e) => {
                                            if (e.target?.value) {
                                              const updatedDataAmount =
                                                salaryEmployeeDataUpdate.map(
                                                  (item) =>
                                                    item.id === data?.id
                                                      ? {
                                                          ...item,
                                                          prorateValue:
                                                            e.target?.value,
                                                          editedStatus: true,
                                                        }
                                                      : item
                                                );
                                              setEnableAll(true);
                                              setDataEmployeeSalaryUpdate(
                                                updatedDataAmount
                                              );
                                            }
                                          }}
                                          class="form-control"
                                          value={data?.prorateValue}
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {data?.editedStatus && (
                                <div className="d-flex align-items-center">
                                  <Button
                                    onClick={handleSubmitSalaryUpdate.bind(
                                      this,
                                      data
                                    )}
                                    style={{
                                      background: "#1FC157",
                                      color: "white",
                                    }}
                                  >
                                    {data?.loadSalaryUpdate ? "Saving" : "Save"}{" "}
                                  </Button>
                                  {data?.status === "FAILED" && (
                                    <Button
                                      className="ml-4"
                                      onClick={() => {
                                        setOpenBulkError(true);
                                        setBulkErrorMessagesSalary(
                                          data?.message
                                        );
                                      }}
                                      style={{
                                        background: "red",
                                        color: "white",
                                      }}
                                    >
                                      ?
                                    </Button>
                                  )}
                                </div>
                              )}

                              {!data?.editedStatus && (
                                <Button
                                  style={{
                                    background: "grey",
                                    color: "silver",
                                  }}
                                >
                                  {data?.loadSalaryUpdate ? "Saving" : "Save"}
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              )}
            </table>
            <br />
            <br />
            {salaryEmployeeDataUpdate?.length > 0 && (
              <div className="text-center pagination-part pt-5">
                <Pagination
                  current={pageSalaryUpdate}
                  total={totalItemsSalaryUpdate}
                  defaultPageSize={10}
                  onChange={paginationSalaryUpdate}
                  itemRender={itemRender}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Manage Payment Methods"
        visible={openBulkPaymentMethod}
        okText="Skip"
        maskClosable={false}
        width={"100%"}
        onCancel={() => {
          setOpenBulkSPaymentUpdate(false);
          setEnableAllPaymentMethod(false);
        }}
        footer={false}
      >
        <div>
          <div className="d-flex justify-content-end align-items-center">
            {paymentMethodLists?.filter((val) => val?.paymentMethodId !== "New")
              ?.length !== 0 && (
              <Button
                onClick={handleSubmitPaymentMethodUpdateBulk}
                className="mr-5"
                style={{
                  background: enableAllPayment ? "#1FC157" : "grey",
                  color: enableAllPayment ? "white" : "silver",
                }}
              >
                {uploadBulkLoad ? "Saving" : "Save All"}
              </Button>
            )}
          </div>
          <br />
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Full Name</th>
                  <th scope="col">Payment Methods</th>
                  <th scope="col">Country</th>
                  <th scope="col">Currency</th>
                  <th scope="col"> Bank</th>
                  <th scope="col">Account Number</th>
                  <th scope="col">Account Name</th>
                  <th scope="col" className="text-center">
                    Make Default
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {!loadEmployeesPayment && (
                <tbody>
                  {paymentMethodLists?.length > 0 && (
                    <>
                      {paymentMethodLists?.map((data, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="text-center">
                                {data?.firstName + " " + data?.lastName}
                              </div>
                              <div className="text-center">
                                ({data?.employeeCode})
                              </div>
                            </td>
                            <td>
                              <div class="">
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    if (e?.target?.value !== "New") {
                                      var findPayment =
                                        data?.paymentMethods?.find(
                                          (payment) =>
                                            payment.id === e.target?.value
                                        );
                                      if (findPayment?.id) {
                                        getBanksByCountry(
                                          findPayment?.countryCode
                                        );
                                        const updatedDataAmount =
                                          paymentMethodLists.map((item) =>
                                            item.id === data?.id
                                              ? {
                                                  ...item,
                                                  paymentMethodId:
                                                    findPayment?.id,
                                                  country:
                                                    findPayment?.countryCode,
                                                  currencyCode:
                                                    findPayment?.currencyCode,
                                                  institutionCode:
                                                    findPayment?.institutionName,
                                                  accountNum:
                                                    findPayment?.accountIdentifier,
                                                  default:
                                                    findPayment?.isDefault,
                                                  accountName:
                                                    findPayment?.accountName,
                                                  editedStatus: true,
                                                }
                                              : item
                                          );
                                        setEnableAllPayment(true);
                                        setPaymentMethodLists(
                                          updatedDataAmount
                                        );
                                      }
                                    } else {
                                      const updatedDataAmount =
                                        paymentMethodLists.map((item) =>
                                          item.id === data?.id
                                            ? {
                                                ...item,
                                                paymentMethodId: "New",
                                                country: "",
                                                currencyCode: "",
                                                default: false,
                                                institutionCode: "",
                                                accountNum: "",
                                                accountName: "",
                                                editedStatus: true,
                                              }
                                            : item
                                        );
                                      setEnableAllPayment(true);
                                      setPaymentMethodLists(updatedDataAmount);
                                    }
                                  }}
                                  value={data?.paymentMethodId}
                                >
                                  <option value="New">
                                    Add Payment Method
                                  </option>
                                  {data?.paymentMethods?.map((method) => {
                                    return (
                                      <option value={method.id}>
                                        {method?.accountName +
                                          "-" +
                                          method?.accountIdentifier +
                                          "-" +
                                          method.institutionName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </td>
                            <td>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  if (e?.target?.value) {
                                    const updatedDataAmount =
                                      paymentMethodLists.map((item) =>
                                        item.id === data?.id
                                          ? {
                                              ...item,
                                              country:
                                                e?.target?.value === "Select"
                                                  ? ""
                                                  : e.target?.value,
                                              editedStatus: true,
                                            }
                                          : item
                                      );
                                    setEnableAllPayment(true);
                                    getBanksByCountry(e.target.value);
                                    setPaymentMethodLists(updatedDataAmount);
                                  }
                                }}
                                value={data?.country}
                              >
                                <option value="country">
                                  Select Payment Country
                                </option>
                                {countryData?.map((country) => {
                                  return (
                                    <option value={country.code}>
                                      {country?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                onChange={(e) => {
                                  if (e?.target?.value) {
                                    const updatedDataAmount =
                                      paymentMethodLists.map((item) =>
                                        item.id === data?.id
                                          ? {
                                              ...item,
                                              currencyCode:
                                                e?.target?.value === "Select"
                                                  ? ""
                                                  : e.target?.value,
                                              editedStatus: true,
                                            }
                                          : item
                                      );
                                    setEnableAllPayment(true);
                                    setPaymentMethodLists(updatedDataAmount);
                                  }
                                }}
                                value={data?.currencyCode}
                              >
                                <option value="currency">
                                  Select Payment Currency
                                </option>
                                {currencyOptions}
                              </select>
                            </td>
                            <td>
                              <Controller
                                name="bankCode"
                                control={control}
                                {...register("bankCode", {
                                  required: false,
                                })}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    value={data.institutionCode}
                                    defaultValue={"Select Bank"}
                                    style={{
                                      width: "150px",
                                      height: "70px !important",
                                      borderRadius: "10px !important",
                                      marginBottom: ".8rem",
                                    }}
                                    className="w-100"
                                    onChange={(val) => {
                                      if (val) {
                                        const updatedDataAmount =
                                          paymentMethodLists.map((item) =>
                                            item.id === data?.id
                                              ? {
                                                  ...item,
                                                  institutionCode:
                                                    val === "Select" ? "" : val,
                                                  editedStatus: true,
                                                }
                                              : item
                                          );
                                        setEnableAllPayment(true);
                                        setPaymentMethodLists(
                                          updatedDataAmount
                                        );
                                      }
                                    }}
                                    options={bankLists}
                                    showSearch
                                    placeholder="Select a Bank"
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                  />
                                )}
                              />
                            </td>
                            <td>
                              <div class="">
                                <div class="">
                                  <div>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      value={data?.accountNum}
                                      onChange={(e) =>
                                        handleInputChangeAccountPayemntMethod(
                                          data,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {data?.computeStarted ? (
                                <div>Validating...</div>
                              ) : (
                                <div>
                                  {data?.accountName ? data.accountName : "NA"}
                                </div>
                              )}
                            </td>
                            <td className="text-center">
                              <div class="mb-3">
                                <div className="pt-2">
                                  <Checkbox
                                    checked={data?.default}
                                    onChange={(e) => {
                                      const updatedDataAmount =
                                        paymentMethodLists.map((item) =>
                                          item.id === data?.id
                                            ? {
                                                ...item,
                                                default: e.target.checked,
                                                editedStatus: true,
                                              }
                                            : item
                                        );
                                      setEnableAllPayment(true);
                                      setPaymentMethodLists(updatedDataAmount);
                                    }}
                                  ></Checkbox>
                                </div>
                              </div>
                            </td>

                            <td>
                              {data?.editedStatus && (
                                <div className="d-flex align-items-center">
                                  <Button
                                    onClick={() => {
                                      setPinModal(true);
                                      setDataRow(data);
                                    }}
                                    style={{
                                      background: "#1FC157",
                                      color: "white",
                                    }}
                                  >
                                    {data?.loadSalaryUpdate ? "Saving" : "Save"}{" "}
                                  </Button>
                                  {data?.status === "FAILED" && (
                                    <Button
                                      className="ml-4"
                                      onClick={() => {
                                        setOpenBulkError(true);
                                        setBulkErrorMessagesSalary(
                                          data?.messages
                                        );
                                      }}
                                      style={{
                                        background: "red",
                                        color: "white",
                                      }}
                                    >
                                      ?
                                    </Button>
                                  )}
                                </div>
                              )}

                              {!data?.editedStatus && (
                                <Button
                                  style={{
                                    background: "grey",
                                    color: "silver",
                                  }}
                                >
                                  {data?.loadSalaryUpdate ? "Saving" : "Save"}
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              )}
            </table>
            <br />
            <br />
            {paymentMethodLists?.length > 0 && (
              <div className="text-center pagination-part pt-5">
                <Pagination
                  current={pagePaymentMethods}
                  total={totalItemsPaymentMethods}
                  defaultPageSize={10}
                  onChange={paginationPaymentMethods}
                  itemRender={itemRender}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={onboardChecklist}
        okText="Skip"
        maskClosable={false}
        width={600}
        onCancel={onCancelOnboardChecklist}
        onOk={onCancelOnboardChecklist}
        footer={false}
      >
        <div className="container">
          <div className="check-area pt-2">
            <div className="container">
              <h4 className="text-center" style={{ color: "#1FC157" }}>
                Welcome to SalarioPay!
              </h4>

              <hr />
              <div>
                <h5 className="text-center pt-3">Get Started Steps</h5>
                <p className="text-center">
                  To get started, please ensure you complete the required steps
                  in the checklist below.
                </p>
              </div>

              <div className="check-area pt-2">
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.contactVerified &&
                      userChecklists?.businessVerified
                        ? true
                        : false
                    }
                  >
                    Verify your Personal & Business Identity{" "}
                  </Checkbox>

                  <Button
                    disabled={
                      userChecklists.contactVerified &&
                      userChecklists?.businessVerified
                        ? true
                        : false
                    }
                    onClick={() => {
                      history.push("/dashboard?mode=kyc");
                    }}
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "75px",
                    }}
                    size="small"
                  >
                    Update
                  </Button>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.positionCompleted
                        ? userChecklists.positionCompleted
                        : ""
                    }
                  >
                    Add Your Organization Positions (Optional)
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/manage-position",
                      state: { auto: true },
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Add
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.departmentCompleted
                        ? userChecklists.departmentCompleted
                        : ""
                    }
                  >
                    Add Your Organization Departments (Optional)
                  </Checkbox>
                  <Link
                    to={{
                      pathname: "/manage-department",
                      state: { auto: true },
                    }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Add
                    </Button>
                  </Link>
                </div>
                <div className="d-flex justify-content-between align-items-center  mb-3">
                  <Checkbox
                    checked={
                      userChecklists.employeeLevelCompleted
                        ? userChecklists.employeeLevelCompleted
                        : ""
                    }
                  >
                    Add Your Organization Employee Levels (Optional)
                  </Checkbox>
                  <Link
                    to={{ pathname: "/employee-level", state: { auto: true } }}
                  >
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Add
                    </Button>
                  </Link>
                </div>
                <br />
                <hr />
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    onClick={onCancelOnboardChecklist}
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "75px",
                    }}
                    size="small"
                  >
                    Skip{" "}
                  </Button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        visible={openBulkError}
        okText="Skip"
        maskClosable={false}
        width={500}
        footer={false}
        onCancel={() => {
          setOpenBulkError(false);
        }}
      >
        <div className="container">
          {bulkErrorMessagesLists.map((msg, i) => {
            return (
              <div className="pb-2" key={i}>
                <Alert message={msg} type="error" showIcon />
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        title="Upload Error Messages"
        visible={failedMsgModal}
        okText="Skip"
        maskClosable={false}
        width={600}
        footer={false}
        onCancel={() => {
          setFailedMsgModal(false);
        }}
      >
        <div className="container">
          {failedErrMsgs.map((msg, i) => {
            return (
              <div className="pb-2" key={i}>
                <Alert message={msg} type="error" showIcon />
              </div>
            );
          })}
        </div>
      </Modal>

      <Modal
        title={false}
        visible={lookUpKycModal}
        footer={false}
        maskClosable={false}
        onCancel={closeLookupModal}
      >
        <div className="pt-5">
          <Alert message="Success" description={msg} type="success" showIcon />
          <br />
          {load && (
            <div class="text-center">
              <Spin />
              <p className="text-info font-weight-bold">
                Verification in progress
              </p>
            </div>
          )}

          <br />

          {confirmationResult.verifyBy === "NONE" ||
            (confirmationResult.verifyBy === "OTP" && (
              <div className="">
                {steps === "personal" ? (
                  <div className="">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={confirmationResult.firstName}
                        disabled
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={confirmationResult.lastName}
                        disabled
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                ) : (
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Business Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={confirmationResult.companyName}
                      disabled
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                )}

                {confirmationResult.verifyBy === "OTP" && (
                  <div>
                    <label>Otp</label>
                    <OtpInput
                      value={pin}
                      onChange={handleChangePin}
                      isInputNum
                      isInputSecure
                      inputStyle={{
                        padding: "15px",
                        border: ".9px solid green",
                        width: "50px",
                      }}
                      numInputs={5}
                      separator={<span>-</span>}
                    />
                  </div>
                )}
              </div>
            ))}

          {confirmationResult.verifyBy === "DATE_OF_REGISTRATION" && (
            <div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Business Name
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={confirmationResult.companyName}
                  disabled
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="col-sm-12 col-md-12">
                <div>
                  <label for="exampleInputEmail1" class="form-label">
                    Business Date of Registration{" "}
                  </label>
                  <DatePicker
                    onChange={onChangeDateOfReg}
                    style={{ height: "3rem", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          )}

          <br />
          <div className="d-flex justify-content-end">
            {confirmationResult.verifyBy !== "NONE" && (
              <button
                type="submit"
                className="btn btn-info m-b-xs"
                style={{ background: "#1FC157", color: "white" }}
                onClick={
                  steps === "business"
                    ? verifyDetails.bind(this, "business")
                    : verifyDetails.bind(this, "personal")
                }
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        width={700}
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setPinModal(false);
          setEmployerPin("");
        }}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          <div className="text-center" style={{ padding: "50px 0px" }}>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <OtpInput
                  value={employerPin}
                  onChange={(p) => setEmployerPin(p)}
                  isInputNum
                  isInputSecure
                  inputStyle={{
                    padding: "15px",
                    border: ".9px solid green",
                    width: "50px",
                    margin: "10px",
                  }}
                  numInputs={4}
                  separator={<span></span>}
                />
                {pinBulk ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="submit"
                      class="btn btn-primary mt-4 w-100"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={handleSubmitPaymentMethodUpdateBulk}
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="submit"
                      class="btn btn-primary mt-4 w-100"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={
                        dataRow?.paymentMethodId === "New"
                          ? handleSubmitPaymentMethodAdd
                          : handleSubmitPaymentMethodUpdate
                      }
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </Modal>

      <CrudOptimizer
        name={addTypeName}
        visible={visible}
        setVisible={setVisible}
        closeModal={closeModal}
        setCrudDatas={setCrudDatas}
        loadAllCrus={loadAllCrus}
      />
    </Layout>
  );
}
