import React from "react";
import { useLocation } from "react-router-dom";
import "./tab.css";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
const role = sessionStorage.getItem("activeRole");
function App(props) {
  const location = useLocation();
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");

  return (
    <div className="card">
      <div className="card-body pt-3 pb-3">
        <div className="link-text">
          <Nav className="d-flex justify-content-between align-items-center">
            {role === "EMPLOYER" && (
              <NavItem>
                <Link
                  to="/company_information"
                  className={
                    locationB && locationA === "company_information"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Company Information
                </Link>
              </NavItem>
            )}

            <NavItem>
              <Link
                to="/security"
                className={
                  locationB && locationA === "security"
                    ? "tab-list-active "
                    : "text-dark"
                }
              >
                Security
              </Link>
            </NavItem>
            {/* <NavItem>
              <Link
                to='/subscription'
                className={
                  locationB && locationA === 'subscription'
                    ? 'tab-list-active'
                    : 'text-dark'
                }
              >
                Subscription
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to='/billing'
                className={
                  locationB && locationA === 'billing'
                    ? 'tab-list-active'
                    : 'text-dark'
                }
              >
                Billing Methods
              </Link>
            </NavItem> */}

            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/manage-position"
                  className={
                    locationB && locationA === "manage-position"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Positions
                </Link>
              </NavItem>
            )}
            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/manage-department"
                  className={
                    locationB && locationA === "manage-department"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Departments
                </Link>
              </NavItem>
            )}

            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/employee-level"
                  className={
                    locationB && locationA === "employee-level"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Employee Levels
                </Link>
              </NavItem>
            )}

            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/paygroup"
                  className={
                    locationB && locationA === "paygroup"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Pay Groups
                </Link>
              </NavItem>
            )}

            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/manage_paystructure"
                  className={
                    locationB && locationA === "manage_paystructure"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Pay Structures
                </Link>
              </NavItem>
            )}

            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/manage_paylevel"
                  className={
                    locationB && locationA === "manage_paylevel"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Pay Levels
                </Link>
              </NavItem>
            )}

            {(role === "EMPLOYER" || role === "HUMAN_RESOURCE") && (
              <NavItem>
                <Link
                  to="/manage_payscale"
                  className={
                    locationB && locationA === "manage_payscale"
                      ? "tab-list-active"
                      : "text-dark"
                  }
                >
                  Pay Scale
                </Link>
              </NavItem>
            )}
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default App;
