import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import axios from "axios";
import config from "../../../../config";
import { Modal, notification, Spin, Checkbox, Select } from "antd";
import { useHttp } from "../../../../hooks/useHttps";
import { NumberFormatter } from "../../../../formatter/index";
import { useForm, Controller } from "react-hook-form";
export default function EmployeeInfo(props) {
  const [load, setLoad] = useState(false);
  const [paygroup, setPaygroup] = useState([]);
  const [openUpdate, setUpdate] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [paygroupData, setPaygroupData] = useState([]);
  const [annualGrossSalary, setAnnualGrossSalary] = useState("");
  const [monthlyGossSalary, setMonthlyGrossSalary] = useState("");
  const [prorateType, setProrateType] = useState("");
  const [salaryDetails, setSalaryDetails] = useState({});
  const [prorateValue, setProrateValue] = useState("");
  const [netSalary, setNetSalary] = useState("");
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [payLevelDatas, setPayLevelData] = useState([]);
  const [payLevel, setPayLevel] = useState("");
  const [payScaleData, setPayScalesData] = useState([]);
  const [monthlyNetSalaryVal, setMonthltyNetSalaryValue] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");
  const [payScale, setPayScale] = useState("");
  const [monthlyNetSalary, setMonthlyNetSalary] = useState("");
  const [bonus, setBonus] = useState("");
  const role = sessionStorage.getItem("activeRole");

  const [checkWorkOptionsVal, setCheckOptionVal] = useState([]);
  const [loadingProrate, prorateDataType] = useHttp(
    `${config.baseUrl}/v1/options/PRORATE_TYPES`,
    []
  );

  const [checkWorkOptions, setCheckWorkOptions] = useState([]);

  const onChangeCheckbox = (checkedValues) => {
    setCheckWorkOptions(checkedValues);
  };

  const checkOptions = [
    {
      label: "Pension",
      value: "Pension",
    },

    {
      label: "Tax",
      value: "Tax",
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const updateSalaryDetail = (e) => {
    e.preventDefault();
    setUpdate(true);
  };
  const closeUpdate = () => {
    setUpdate(false);
    setPaygroup("");
    setAnnualGrossSalary("");
  };
  useEffect(() => {
    getContactDetails();
    getPaygroup();
    getPayLevel();
  }, []);

  const getPayLevel = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-levels/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayLevelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPayScales = (id) => {
    setPayLevel(id);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-scales/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&payLevelId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayScalesData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPaygroup = (country) => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(
            res.data.result?.map((item) => ({
              value: item?.id,
              label: item?.groupName,
            }))
          );
        } else {
        }
      })

      .catch((err) => {
        if (err) {
        }
      });
  };

  const getContactDetails = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setAnnualGrossSalary(contactDetails?.annualGrossSalary);
          setMonthlyNetSalary(contactDetails?.monthlyNetSalary);
          setPaygroup(contactDetails?.payGroupId);
          setProrateType(contactDetails?.proRateType);
          setPayScale(contactDetails?.payScale)
          setPayLevel(contactDetails?.payLevel)
          setProrateValue(contactDetails?.proRateValue);
          setSalaryDetails(contactDetails);
          setMonthlyGrossSalary(contactDetails?.monthlyGrossSalary);
          setBonus(contactDetails?.bonusAmount);
          setPaygroup(contactDetails?.payGroupId)
          setContactDetails(contactDetails);
          if (contactDetails?.computePension) {
            checkWorkOptions.push("Pension");
            checkWorkOptionsVal.push("Pension");
          }
          if (contactDetails?.computeTax) {
            checkWorkOptionsVal.push("Tax");
            checkWorkOptions?.push("Tax");
          }
        } else {
          setAnnualGrossSalary(0);
          setMonthlyGrossSalary(0);
          setMonthlyNetSalary(0);
          setPaygroup("");
          setProrateType("");
          setProrateValue("");
          getPaygroup("");
          setBonus("");
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const errorNote = () => {
    Notification("error", "Error", "Permission not allowed");
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const formatNumber = (num) => {
    return "₦" + new Intl.NumberFormat().format(num);
  };

  const handleSalaryUpdate = (e) => {
    e.preventDefault();
    const data = {
      annualGrossSalary,
      payGroupId: paygroup,
      prorateType: prorateType,
      computePension: checkWorkOptions?.includes("Pension"),
      computeTax: checkWorkOptions?.includes("Tax"),
      prorateValue: prorateValue,
      bonusAmount: bonus,
      payLevelId: payLevel === "Select" ? "" : payLevel,
      payScaleId: payScale === "Select" ? "" : payScale,
    };
    setLoadUpdate(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/salary-detail/${sessionStorage.getItem(
          "employeeID"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdate(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          setTimeout(() => {
            window.location.reload(false);
          }, 1500);
          setUpdate(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdate(false);
        if (err) {
        }
      });
  };

  const getMonthlyNetSalary = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/employees/pay-structure?annualGrossSalary=${annualGrossSalary}&computeNHF=false&computePension=${checkWorkOptions?.includes(
          "Pension"
        )}&computeTax=${checkWorkOptions?.includes("Tax")}&country=${
          contactDetails?.country
        }&currency=${contactDetails?.salaryCurrency}&employeeType=${
          contactDetails?.employeeType
        }&employerId=${sessionStorage?.getItem("employer_id")}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setMonthltyNetSalaryValue(res.data.result.monthlyNetSalary);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (annualGrossSalary) {
      getMonthlyNetSalary();
    }
  }, [annualGrossSalary, checkWorkOptions]);

  const paygroupOptions = paygroupData.map((paygroup) => {
    return <option value={paygroup.id}>{paygroup.groupName}</option>;
  });

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">Salary Detail</div>

            <div class="card-body">
              {load && (
                <div className="text-center">
                  <Spin />
                </div>
              )}

              {!load && (
                <form>
                  <div className="text-center">{loadUpdate && <Spin />}</div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        {monthlyNetSalaryVal !== 0 ? (
                          <label for="exampleInputEmail1" class="form-label">
                            Annual Gross Salary{" "}
                            <span style={{ color: "#1fc157" }}>
                              (Monthly Net Salary -{" "}
                              {contactDetails?.salaryCurrency}{" "}
                              {Intl.NumberFormat("en-US").format(
                                monthlyNetSalaryVal
                              )}
                              )
                            </span>
                          </label>
                        ) : (
                          <label for="exampleInputEmail1" class="form-label">
                            Annual Gross Salary
                          </label>
                        )}

                        <Controller
                          name="annualGrossery"
                          control={control}
                          {...register("annualGrossery", {
                            required: false,
                            onChange: (e) =>
                              setAnnualGrossSalary(e.target.value),
                            value: annualGrossSalary,
                          })}
                          render={({ field }) => (
                            <NumberFormatter
                              {...field}
                              label=""
                              value={annualGrossSalary}
                              name="annualGrossery"
                              error={errors.annualGrossery?.message}
                              prefix={"₦"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Monthly Gross Salary
                        </label>

                        <Controller
                          name="monthlyGossSalary"
                          control={control}
                          disabled
                          {...register("monthlyGossSalary", {
                            required: false,
                            value: monthlyGossSalary,
                          })}
                          render={({ field }) => (
                            <NumberFormatter
                              {...field}
                              value={monthlyGossSalary}
                              label=""
                              name="monthlyGossSalary"
                              error={errors.monthlyGossSalary?.message}
                              prefix={"₦"}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Monthly Net Salary
                        </label>

                        <Controller
                          name="monthlyNetSalary"
                          control={control}
                          disabled
                          {...register("monthlyGossSalary", {
                            required: false,
                            value: monthlyNetSalary,
                          })}
                          render={({ field }) => (
                            <NumberFormatter
                              {...field}
                              value={monthlyNetSalary}
                              label=""
                              name="monthlyGossSalary"
                              error={errors.monthlyNetSalary?.message}
                              prefix={"₦"}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Salary Bonus
                        </label>

                        <Controller
                          name="bonusAmount"
                          control={control}
                          {...register("bonusAmount", {
                            required: false,
                            onChange: (e) => setBonus(e.target.value),
                            value: bonus,
                          })}
                          render={({ field }) => (
                            <NumberFormatter
                              {...field}
                              label=""
                              name="bonusAmount"
                              value={bonus}
                              error={errors.bonusAmount?.message}
                              prefix={"₦"}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Prorate Type
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setProrateType(e.target.value)}
                          value={prorateType}
                        >
                          {prorateDataType.map((type) => {
                            return (
                              <option value={type.code}>
                                {type.value
                                  ? type.value.replace(/_+/g, " ")
                                  : ""}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {prorateType === "NONE" && (
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Prorate Value
                          </label>

                          <div>
                            <input
                              type="text"
                              onChange={(e) => setProrateValue(e.target?.value)}
                              class="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              value="NOT APPLICABLE"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {prorateType !== "NONE" && (
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {prorateType
                              ?.replace("_", " ")
                              ?.replace(/_+/g, " ")}
                          </label>
                          {prorateType?.includes("AMOUNT") ? (
                            <Controller
                              name="prorateValue"
                              control={control}
                              {...register("prorateValue", {
                                required: false,
                                onChange: (e) =>
                                  setProrateValue(e.target.value),
                                value: prorateValue,
                              })}
                              render={({ field }) => (
                                <NumberFormatter
                                  {...field}
                                  label=""
                                  name="prorateValue"
                                  value={prorateValue}
                                  error={errors.prorateValue?.message}
                                  prefix={"₦"}
                                />
                              )}
                            />
                          ) : (
                            <div>
                              <input
                                type="text"
                                onChange={(e) =>
                                  setProrateValue(e.target?.value)
                                }
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={prorateValue}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label for="exampleInputEmail1" class="form-label">
                            Pay Level
                          </label>
                        </div>
                        <select
                          class="form-select"
                          name="level"
                          aria-label="Default select example"
                          onChange={(e) => getPayScales(e.target.value)}
                          value={payLevel}
                        >
                          <option selected>Select</option>
                          {payLevelDatas.map((level) => {
                            return (
                              <option value={level.id}>{level.name}</option>
                            );
                          })}
                        </select>
                        {errors.paylevel && (
                          <span className="validate-error">
                            {errors.paylevel.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label for="exampleInputEmail1" class="form-label">
                            Pay Scale
                          </label>
                        </div>
                        <select
                          class="form-select"
                          name="payScale"
                          aria-label="Default select example"
                          onChange={(e) => setPayScale(e.target.value)}
                          value={payScale}
                        >
                          <option selected>Select</option>
                          {payScaleData.map((scale) => {
                            return (
                              <option value={scale.id}>{scale.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label for="exampleInputEmail1" class="form-label">
                          Pay Group
                        </label>
                      </div>
                      <div class="mb-3 mt-1">
                        <Select
                          mode="multiple"
                          value={paygroup}
                          style={{
                            width: "100%",
                            marginRight: "1rem",
                          }}
                          placeholder=""
                          onChange={(val) => {
                            setPaygroup(val);
                          }}
                          options={paygroupData ? paygroupData : []}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div className="pt-2 mb-3">
                        <div>
                          <label for="exampleInputEmail1" class="form-label">
                            Statutory Deductions
                          </label>
                        </div>
                        <div>
                          <Checkbox.Group
                            options={checkOptions}
                            defaultValue={checkWorkOptionsVal}
                            onChange={onChangeCheckbox}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{
                      background: "#1FC157",
                      marginTop: "1rem",
                      color: "white",
                    }}
                    onClick={
                      role === "HUMAN_RESOURCES"
                        ? errorNote
                        : handleSalaryUpdate
                    }
                  >
                    Update Salary Detail
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Update Salary Detail"}
        visible={openUpdate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeUpdate}
      >
        <div className="container">
          {load && (
            <div className="text-center">
              {" "}
              <Spin />
            </div>
          )}
          <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Annual Gross Salary
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setAnnualGrossSalary(e.target.value)}
                aria-describedby="emailHelp"
                value={annualGrossSalary}
              />
            </div>
            <div class="mb-3">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => setPaygroup(e.target.value)}
                value={paygroup}
              >
                <option selected>Select Paygroup</option>
                {paygroupOptions}
              </select>
            </div>
            <div class="mb-3">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => setProrateType(e.target.value)}
                value={prorateType}
              >
                <option selected>Select Prorate Type</option>
                {prorateDataType.map((type) => {
                  return (
                    <option value={type.code}>
                      {type.value ? type.value.replace("_", " ") : ""}
                    </option>
                  );
                })}
              </select>
            </div>
            {prorateType && (
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  {prorateType === "FLAT_AMOUNT"
                    ? "Prorate Amount"
                    : "Prorate Percentage (%)"}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  onChange={(e) => setProrateValue(e.target.value)}
                  aria-describedby="emailHelp"
                  value={prorateValue}
                />
              </div>
            )}
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Salary Bonus
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setBonus(e.target.value)}
                aria-describedby="emailHelp"
                value={bonus}
              />
            </div>

            <div className="d-flex justify-content-end align-items-between">
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  marginTop: "1rem",
                  color: "white",
                }}
                onClick={handleSalaryUpdate}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </Layout>
  );
}
